// PromotionModal.js
import React from "react";
import "./css/PromotionModal.css"; // Add appropriate styling

const PromotionModal = ({ onPromote, color }) => {
  // Unicode symbols for chess pieces
  const pieceSymbols = {
    q: "♛", // Queen
    r: "♜", // Rook
    b: "♝", // Bishop
    n: "♞", // Knight
  };

  return (
    <div className="promotion-modal">
      <h2>Promote Pawn to:</h2>
      <div className="promotion-options">
        {Object.entries(pieceSymbols).map(([piece, symbol]) => (
          <button
            key={piece}
            onClick={() => onPromote(piece)}
            className={`promotion-button ${color}`}
          >
            {symbol}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PromotionModal;
