import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h2>Terms and Conditions</h2>
      <p>
        Welcome to HXABYTE RECORDS! By accessing or using our website, you agree
        to comply with and be bound by the following terms and conditions.
        Please read these terms carefully before using our services.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using HXABYTE RECORDS, you agree to be bound by these
        Terms and Conditions. If you disagree with any part of these terms, you
        may not access or use our services.
      </p>

      <h2>2. Description of Service</h2>
      <p>
        HXABYTE RECORDS provides a platform for purchasing and downloading music
        files, tutorial videos, and beats. Additionally, we offer subscription
        services for access to exclusive content and features.
      </p>

      <h2>3. Purchases and Refunds</h2>
      <p>
        a. <strong>Music Files, Tutorial Videos, and Beats:</strong> Once
        purchased, these digital products cannot be refunded or exchanged.
        Please ensure that you are satisfied with your selection before
        completing the purchase.
      </p>

      <h2>4. Subscription Services</h2>
      <p>
        a. <strong>Subscription Plans:</strong> We offer various subscription
        plans with different durations and features. By subscribing, you agree
        to pay the recurring subscription fee associated with your chosen plan.
      </p>
      <p>
        b. <strong>Subscription Cancellation:</strong> You may cancel your
        subscription at any time. Upon cancellation, you will retain access to
        the subscription content until the end of the current billing cycle.
      </p>

      <h2>5. Seller Share and Platform Fees</h2>
      <p>
        For products sold by other music producers, HXABYTE RECORDS will
        allocate 70% of the purchase price to the seller, and the platform will
        retain 30% as platform fees.
      </p>

      <h2>6. Shipping & Delivery</h2>
      <p>
        As HXABYTE RECORDS deals exclusively in digital goods, there is no
        physical shipping involved. Upon completion of your purchase, you will
        receive a download link to access your purchased music files, tutorial
        videos, or beats.
      </p>

      <h2>7. Intellectual Property</h2>
      <p>
        All music files, tutorial videos, beats, and other content available on
        HXABYTE RECORDS are protected by copyright and other intellectual
        property laws. You may not reproduce, distribute, or transmit any
        content without prior authorization.
      </p>

      <h2>8. User Conduct</h2>
      <p>
        a. You agree not to use HXABYTE RECORDS for any unlawful or prohibited
        purpose.
      </p>
      <p>
        b. You must provide accurate and complete information when making
        purchases or creating an account on our platform.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        HXABYTE RECORDS, its affiliates, and their respective directors,
        officers, employees, and agents shall not be liable for any indirect,
        incidental, special, or consequential damages arising out of or in
        connection with the use of our services.
      </p>

      <h2>10. Modifications to Terms</h2>
      <p>
        We reserve the right to update or modify these Terms and Conditions at
        any time without prior notice. Your continued use of HXABYTE RECORDS
        after any such changes constitutes your acceptance of the revised terms.
      </p>

      <h2>11. Governing Law</h2>
      <p>
        These Terms and Conditions shall be governed by and construed in
        accordance with the laws of Indian & Tamil Nadu Goverment, without
        regard to its conflict of law provisions.
      </p>

      <h2>12. Contact Us</h2>
      <p>
        If you have any questions or concerns regarding these Terms and
        Conditions, please contact us at +91 8248306704 or email at
        hxaabytetech@gmail.com.
      </p>

      <p>
        By using HXABYTE RECORDS, you acknowledge that you have read,
        understood, and agree to be bound by these Terms and Conditions. Thank
        you for choosing HXABYTE RECORDS!
      </p>
    </div>
  );
};

export default TermsAndConditions;
