import React, { useState } from "react";
import { useUser } from "./UserContext";
import "./css/LoginModal.css";
import { ClipLoader } from "react-spinners";

function LoginModal({ isLoginOpen, onLoginClose, toggleRegisterModal }) {
  const { login, loginWithPhone } = useUser(); // Import loginWithPhone function from context
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneLogin, setIsPhoneLogin] = useState(false); // State to toggle between email and phone login
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP input visibility

  const handleEmailLogin = async (event) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await login(email, password, () => {
        onLoginClose();
        setIsLoading(false);
      });
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handleSendOtp = async () => {
    setError("");
    setIsLoading(true);
    try {
      // Sending OTP logic
      await loginWithPhone(phone); // Just send the OTP
      setOtpSent(true); // Show the OTP input after OTP is sent
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      // Verifying OTP logic
      await loginWithPhone(phone, otp, () => {
        onLoginClose();
        setIsLoading(false);
      });
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  if (!isLoginOpen) return null;

  return (
    <div className="login-modal-overlay" onClick={onLoginClose}>
      <div className="login-modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="login-modal-close" onClick={onLoginClose}>
          &times;
        </span>
        <div className="dot-selector-container">
          <input
            type="radio"
            id="emailLogin"
            name="loginType"
            checked={!isPhoneLogin}
            onChange={() => setIsPhoneLogin(false)}
          />
          <label htmlFor="emailLogin">Email Login</label>

          <input
            type="radio"
            id="phoneLogin"
            name="loginType"
            checked={isPhoneLogin}
            onChange={() => setIsPhoneLogin(true)}
          />
          <label htmlFor="phoneLogin">Phone Login</label>
        </div>
        {isPhoneLogin ? (
          <form
            onSubmit={otpSent ? handleVerifyOtp : (e) => e.preventDefault()}
          >
            {error && <p className="login-error">{error}</p>}
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone Number"
              className="login-modal-input"
              disabled={otpSent}
            />
            {!otpSent ? (
              <button
                type="button"
                className="login-modal-button"
                onClick={handleSendOtp}
                disabled={isLoading || !phone}
              >
                {isLoading ? <ClipLoader size={10} color="#fff" /> : "Send OTP"}
              </button>
            ) : (
              <>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="OTP"
                  className="login-modal-input"
                />
                <button
                  type="submit"
                  className="login-modal-button"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <ClipLoader size={10} color="#fff" />
                  ) : (
                    "Verify OTP"
                  )}
                </button>
              </>
            )}
          </form>
        ) : (
          <form onSubmit={handleEmailLogin}>
            {error && <p className="login-error">{error}</p>}
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="login-modal-input"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="login-modal-input"
            />
            <button
              type="submit"
              className="login-modal-button"
              disabled={isLoading}
            >
              {isLoading ? <ClipLoader size={10} color="#fff" /> : "Login"}
            </button>
          </form>
        )}
        <span className="login-modal-text">
          Not a user? Register{" "}
          <button className="login-modal-link" onClick={toggleRegisterModal}>
            here
          </button>
          .
        </span>
      </div>
    </div>
  );
}

export default LoginModal;
