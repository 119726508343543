import React, { useState, useRef, useEffect } from "react";
import { FaPlus, FaMinus, FaPlay, FaStop } from "react-icons/fa";
import "./css/SongPlayer.css";
import { usePatternInsSeq } from "./PatternInsSeqContext";
import { useUser } from "./UserContext";
import { Databases, Query } from "appwrite";
import client from "./appwriteConfig";
import InstrumentAudioContext from "./InstrumentAudioContext";

const databases = new Databases(client);

const generateNotes = () => {
  const notes = [];
  const noteNames = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  for (let octave = 0; octave < 5; octave++) {
    for (const note of noteNames) {
      notes.push(`${note}${octave}`);
    }
  }
  return notes.reverse();
};

const SongPlayer = ({ bpm, setBpm, pads }) => {
  const { user } = useUser();
  const {
    fetchBeatPatterns,
    beatPatterns = [],
    fetchNotesPianoRoll,
  } = usePatternInsSeq();
  const [fetchedPatterns, setFetchedPatterns] = useState([]);
  const [patternsFetched, setPatternsFetched] = useState(false);
  const [rows] = useState(1);
  const [columns] = useState(100);
  const [dropdownData, setDropdownData] = useState([]);
  const [activeCell, setActiveCell] = useState({
    row: null,
    col: null,
    type: null,
  });
  const [cellValues, setCellValues] = useState({});
  const [shouldOpenDropdown, setShouldOpenDropdown] = useState(false);
  const [sequences, setSequences] = useState(
    pads.map(() => Array(16).fill(false))
  );
  const [highlightedColumn, setHighlightedColumn] = useState(null);
  const [ledIndex, setLedIndex] = useState(0);
  const [currentBar, setCurrentBar] = useState(0);
  const [currentBeatPattern, setCurrentBeatPattern] = useState(null);
  const [selectedPatternNumbers, setSelectedPatternNumbers] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [notes, setNotes] = useState(generateNotes());
  const globalIntervalRef = useRef(null);
  const beatIntervalRef = useRef(null);
  const tableRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setActiveCell({ row: null, col: null, type: null });
        setShouldOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tableRef]);

  const handleCellClick = async (rowIndex, colIndex, type) => {
    if (
      activeCell.row === rowIndex &&
      activeCell.col === colIndex &&
      shouldOpenDropdown
    ) {
      setActiveCell({ row: null, col: null, type: null });
      setShouldOpenDropdown(false);
      return;
    }

    setActiveCell({ row: null, col: null, type: null });
    setShouldOpenDropdown(false);

    let data = [];
    if (type === "beat") {
      await fetchBeatPatterns();
      if (beatPatterns) {
        data = beatPatterns.map((pattern) => ({
          ...pattern,
          displayName: `${pattern.patternNumber}`,
        }));
      }
    }
    data.push({ $id: "empty", displayName: "Empty" });

    setDropdownData(data);
    setActiveCell({ row: rowIndex, col: colIndex, type });
    setShouldOpenDropdown(true);
  };

  const handleDropdownItemClick = async (item, rowIndex, colIndex) => {
    const newValues = { ...cellValues };
    const newPatternNumbers = { ...selectedPatternNumbers };

    if (item.$id === "empty") {
      delete newValues[`${rowIndex}-${colIndex}`];
      delete newPatternNumbers[`${rowIndex}-${colIndex}`];
    } else {
      newValues[`${rowIndex}-${colIndex}`] = item.displayName;
      newPatternNumbers[`${rowIndex}-${colIndex}`] = item.displayName;

      if (rowIndex === 0) {
        const selectedPattern = beatPatterns.find(
          (pattern) => `${pattern.patternNumber}` === item.displayName
        );

        if (selectedPattern) {
          newPatternNumbers[`0-${colIndex}`] = selectedPattern.patternNumber;

          const response = await databases.listDocuments(
            process.env.REACT_APP_DATABASE_ID,
            process.env.REACT_APP_COLLECTION_BEAT_PATTERN_ID,
            [
              Query.equal("patternNumber", selectedPattern.patternNumber),
              Query.equal("userEmail", user.email),
            ]
          );
          const document = response.documents[0];
          if (document) {
            const parsedInstruments = JSON.parse(
              document.instrumentsJson || "{}"
            );
            for (const instrumentKey in parsedInstruments) {
              const instrument = parsedInstruments[instrumentKey];
              const notes = await fetchNotesPianoRoll(
                instrument.PianoRollPatternNo
              );
              console.log(
                `Notes for ${instrument.InsName} with patternNo ${instrument.PianoRollPatternNo}:`,
                notes
              );
            }
          }
        }
      }
    }

    setCellValues(newValues);
    setSelectedPatternNumbers(newPatternNumbers);
    setActiveCell({ row: null, col: null, type: null });
    setShouldOpenDropdown(false);
  };

  const loadAllPatterns = async () => {
    try {
      const patterns = [];
      for (let colIndex = 0; colIndex < columns; colIndex++) {
        const patternNumber = selectedPatternNumbers[`0-${colIndex}`];
        if (patternNumber) {
          const response = await databases.listDocuments(
            process.env.REACT_APP_DATABASE_ID,
            process.env.REACT_APP_COLLECTION_BEAT_PATTERN_ID,
            [
              Query.equal("patternNumber", patternNumber),
              Query.equal("userEmail", user.email),
            ]
          );
          const document = response.documents[0];
          if (document) {
            try {
              const parsedKits = JSON.parse(document.kitsJson);
              const parsedInstruments = JSON.parse(
                document.instrumentsJson || "{}"
              );
              const sequences = pads.map((pad) => {
                const kit = parsedKits[pad.kitName];
                const instrument = parsedInstruments[pad.kitName];
                return {
                  kitUrl: pad.kitUrl,
                  sequence: kit
                    ? kit.sequence.map((beat) => beat === 1)
                    : Array(16).fill(false),
                  volume: kit ? kit.kitVolume : 0.5,
                  instrumentName: instrument ? instrument.InsName : "",
                  instrumentPatternNo: instrument
                    ? instrument.PianoRollPatternNo
                    : "",
                  instrumentVolume: instrument ? instrument.InsVolume : 0.5,
                };
              });
              patterns.push({
                colIndex,
                sequences,
                kitsJson: document.kitsJson,
                instrumentsJson: document.instrumentsJson,
              });
            } catch (jsonError) {
              console.error(
                `Failed to parse kitsJson or instrumentsJson for patternNumber ${patternNumber}:`,
                jsonError
              );
            }
          }
        }
      }
      return patterns;
    } catch (error) {
      console.error("Failed to load patterns:", error);
      return [];
    }
  };

  const findLastActiveColumn = (patterns) => {
    let lastActiveColumn = -1;
    for (let colIndex = columns - 1; colIndex >= 0; colIndex--) {
      for (const { colIndex: patternColIndex, sequences } of patterns) {
        if (patternColIndex === colIndex) {
          for (const sequenceData of sequences) {
            if (
              Array.isArray(sequenceData.sequence) &&
              sequenceData.sequence.some((beat) => beat)
            ) {
              lastActiveColumn = colIndex;
              break;
            }
          }
        }
      }
      if (lastActiveColumn !== -1) break;
    }
    return lastActiveColumn;
  };

  const handleBeatHeaderClick = async () => {
    console.log("Selected Pattern Numbers:", selectedPatternNumbers);

    const selectedBeats = Object.keys(selectedPatternNumbers)
      .filter((key) => key.startsWith("0-"))
      .map((key) => {
        const colIndex = key.split("-")[1];
        const patternNumber = selectedPatternNumbers[key];
        return `Column ${parseInt(colIndex) + 1}: Pattern ${patternNumber}`;
      });

    console.log("Selected Beats Patterns:", selectedBeats);
    await playAllPatterns();
  };

  const playAllPatterns = async () => {
    const patterns = await fetchAndLogPatterns();
    const lastActiveColumn = findLastActiveColumn(patterns);

    for (let colIndex = 0; colIndex <= lastActiveColumn; colIndex++) {
      setCurrentBar(colIndex);

      for (let stepIndex = 0; stepIndex < 16; stepIndex++) {
        setLedIndex(stepIndex);

        const beatPattern = patterns.find(
          (pattern) => pattern.colIndex === colIndex
        );
        if (beatPattern) {
          const { sequences } = beatPattern;
          for (const sequenceData of sequences) {
            if (sequenceData.sequence[stepIndex]) {
              InstrumentAudioContext.playSound(
                sequenceData.kitUrl,
                sequenceData.volume
              );
            }
            if (sequenceData.instrumentPatternNo) {
              const notes = await fetchNotesPianoRoll(
                sequenceData.instrumentPatternNo
              );
              for (const note of notes) {
                InstrumentAudioContext.playPianoRollNote(
                  note,
                  sequenceData.instrumentVolume
                );
              }
            }
          }
        }

        await new Promise((resolve) => setTimeout(resolve, (60 / bpm) * 250));
      }
    }

    setIsPlaying(false);
  };

  const fetchAndLogPatterns = async () => {
    try {
      const patterns = await loadAllPatterns();
      setFetchedPatterns(patterns);

      console.log("Fetched Patterns:");
      patterns.forEach((pattern) => {
        console.log(`Column ${pattern.colIndex + 1}:`, pattern.kitsJson);
      });

      setPatternsFetched(true);
      return patterns;
    } catch (error) {
      console.error("Failed to load patterns:", error);
      return [];
    }
  };

  const handleBpmChange = (change) => {
    setBpm((prev) => Math.max(30, Math.min(300, prev + change)));
  };

  const stopAllPatterns = () => {
    setIsPlaying(false);
    setLedIndex(0);
    setCurrentBar(0);
    clearInterval(globalIntervalRef.current);
    InstrumentAudioContext.stopAllNotes();
    clearInterval(beatIntervalRef.current);
  };

  return (
    <div className="song-player">
      <div className="song-player-header">
        <h3 className="sp-title">Song Player</h3>
        <button onClick={playAllPatterns}>
          <FaPlay />
        </button>{" "}
        <button onClick={stopAllPatterns}>
          <FaStop />
        </button>
        <div className="bpm-controls">
          <button onClick={() => handleBpmChange(-5)} className="bpm-button">
            <FaMinus />
          </button>
          <span className="bpm-display">{bpm} BPM</span>
          <button onClick={() => handleBpmChange(5)} className="bpm-button">
            <FaPlus />
          </button>
        </div>
      </div>
      <div className="led-container">
        {Array.from({ length: 16 }).map((_, index) => (
          <div
            key={index}
            className={`led ${index === ledIndex ? "led-on" : ""}`}
          ></div>
        ))}
      </div>
      <div className="song-player-grid" ref={tableRef}>
        <table>
          <thead>
            <tr>
              <th className="top-left-cell"></th>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <th
                  key={colIndex}
                  className={`sticky-top ${
                    currentBar === colIndex ? "active-bar" : ""
                  }`}
                  onClick={() => {
                    playAllPatterns();
                  }}
                >
                  {colIndex + 1}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="sticky-left" onClick={handleBeatHeaderClick}>
                Beat
              </td>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <td
                  key={colIndex}
                  className={`song-player-cell pink-cell ${
                    highlightedColumn === colIndex ? "highlighted-column" : ""
                  }`}
                  onClick={() => handleCellClick(0, colIndex, "beat")}
                >
                  {activeCell.row === 0 && activeCell.col === colIndex && (
                    <div className="dropdown open">
                      {dropdownData.map((item) => (
                        <div
                          key={item.$id}
                          onClick={() =>
                            handleDropdownItemClick(item, 0, colIndex)
                          }
                        >
                          {item.displayName}
                        </div>
                      ))}
                    </div>
                  )}
                  {cellValues[`0-${colIndex}`]}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SongPlayer;
