import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Square from "./Square";
import GameOverModal from "./GameOverModal";
import PromotionModal from "./PromotionModal";
import {
  getAllPossibleMoves,
  getCastlingMoves,
  isPieceMovable,
  moveKing,
  playSound,
  isKingInCheck,
  isCheckmate,
  generateLegalMoves,
  filterOutMovesThatCauseCheck,
  evaluateBoard,
  minimax,
  makeMove,
} from "./chessFunctions";
import "./css/Board.css";
import { useUser } from "../UserContext";

// Import sound files
import pieceMoveSound from "./sounds/piece_move.wav";
import pieceTakesSound from "./sounds/piece_takes.wav";
import castlingSound from "./sounds/castling.wav";
import checkSound from "./sounds/check.wav";
import checkmateSound from "./sounds/checkmate.wav";
import queenMoveSound from "./sounds/queen_move.wav";

const initialBoardSetup = [
  ["r", "n", "b", "q", "k", "b", "n", "r"],
  ["p", "p", "p", "p", "p", "p", "p", "p"],
  ["", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", ""],
  ["", "", "", "", "", "", "", ""],
  ["P", "P", "P", "P", "P", "P", "P", "P"],
  ["R", "N", "B", "Q", "K", "B", "N", "R"],
];

const Board = () => {
  const [difficulty, setDifficulty] = useState("medium"); // Default difficulty
  const [userEmail, setUserEmail] = useState("");
  const [board, setBoard] = useState(initialBoardSetup);
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [legalMoves, setLegalMoves] = useState([]);
  const [isWhiteTurn, setIsWhiteTurn] = useState(true);
  const [inCheck, setInCheck] = useState({ white: false, black: false });
  const [checkmate, setCheckmate] = useState(false);
  const [winner, setWinner] = useState(null);
  const [castlingRights, setCastlingRights] = useState({
    whiteKingSide: true,
    whiteQueenSide: true,
    blackKingSide: true,
    blackQueenSide: true,
  });
  const [promotion, setPromotion] = useState(null); // State to manage pawn promotion

  const [gameStarted, setGameStarted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("white");

  const { user } = useUser(); // Access user information from UserContext

  // AI move management, ensuring only one move per turn
  useEffect(() => {
    if (gameStarted) {
      if (
        (selectedColor === "black" && isWhiteTurn) ||
        (selectedColor === "white" && !isWhiteTurn)
      ) {
        setTimeout(handleAIMove, 500); // AI makes a move after a short delay
      }
    }
  }, [isWhiteTurn, gameStarted]);
  useEffect(() => {
    if (user && user.email) {
      setUserEmail(user.email); // Set the user email to state if it exists
    }
  }, [user]);
  const handleAIMove = () => {
    const aiColor = selectedColor === "white" ? "black" : "white";
    let depth;

    // Set AI depth based on selected difficulty
    if (difficulty === "easy") {
      depth = 1; // Shallow search for easy difficulty
    } else if (difficulty === "medium") {
      depth = 3; // Moderate search for medium difficulty
    } else if (difficulty === "hard") {
      depth = 5; // Deep search for hard difficulty
    }

    let bestMove = null;
    let bestMoveValue = -Infinity;

    const possibleMoves = getAllPossibleMoves(aiColor, board);
    for (const move of possibleMoves) {
      const newBoard = makeMove(board, move);
      const moveValue = minimax(
        newBoard,
        depth - 1,
        false,
        -Infinity,
        Infinity
      );
      if (moveValue > bestMoveValue) {
        bestMoveValue = moveValue;
        bestMove = move;
      }
    }

    if (bestMove) {
      const [startRow, startCol] = bestMove.start;
      const [endRow, endCol] = bestMove.end;
      const newBoard = board.map((r) => [...r]);
      newBoard[endRow][endCol] = board[startRow][startCol];
      newBoard[startRow][startCol] = "";
      setBoard(newBoard);

      setIsWhiteTurn(!isWhiteTurn); // Switch turn back to the player after AI move
      playSound(pieceMoveSound);
    }
  };

  const handleSquareClick = (row, col) => {
    if (
      checkmate ||
      !gameStarted ||
      (selectedColor === "black" && isWhiteTurn) ||
      (selectedColor === "white" && !isWhiteTurn)
    )
      return; // Prevent moves if it's not the player's turn

    const piece = board[row][col];

    if (selectedPiece) {
      if (legalMoves.some((move) => move[0] === row && move[1] === col)) {
        const newBoard = board.map((r) => [...r]);
        const targetPiece = newBoard[row][col];
        const movingPiece = board[selectedPiece.row][selectedPiece.col]; // Store the moving piece before updating

        // Move piece to the new square
        newBoard[row][col] = movingPiece;
        // Remove piece from the original square
        newBoard[selectedPiece.row][selectedPiece.col] = "";

        // Check for pawn promotion
        if (
          (movingPiece === "P" && row === 0) || // White pawn reaches 8th rank
          (movingPiece === "p" && row === 7) // Black pawn reaches 1st rank
        ) {
          setPromotion({
            row,
            col,
            color: movingPiece === "P" ? "white" : "black",
          });
          setBoard(newBoard); // Keep the pawn on the board while showing promotion modal
        } else {
          setBoard(newBoard); // Update board without promotion
          setIsWhiteTurn(!isWhiteTurn); // Switch turn to AI after player move
        }

        setSelectedPiece(null);
        setLegalMoves([]);

        // Castling logic
        if (
          movingPiece.toLowerCase() === "k" &&
          Math.abs(selectedPiece.col - col) === 2
        ) {
          if (col > selectedPiece.col) {
            // Kingside castling
            newBoard[row][7] = ""; // Clear rook from its original position
            newBoard[row][col - 1] = isWhiteTurn ? "R" : "r"; // Place rook next to the king
          } else {
            // Queenside castling
            newBoard[row][0] = ""; // Clear rook from its original position
            newBoard[row][col + 1] = isWhiteTurn ? "R" : "r"; // Place rook next to the king
          }
          playSound(castlingSound);
          setBoard(newBoard);
        }

        // Check for check and checkmate
        if (isKingInCheck(newBoard, isWhiteTurn ? "black" : "white")) {
          if (isCheckmate(newBoard, isWhiteTurn ? "black" : "white")) {
            playSound(checkmateSound);
            setCheckmate(true);
            setWinner(isWhiteTurn ? "White" : "Black");
          } else {
            playSound(checkSound);
            setInCheck({ ...inCheck, [isWhiteTurn ? "black" : "white"]: true });
          }
        } else {
          setInCheck({ white: false, black: false });
          if (!checkmate) {
            if (targetPiece) {
              playSound(pieceTakesSound);
            } else if (movingPiece.toLowerCase() === "q") {
              playSound(queenMoveSound);
            } else {
              playSound(pieceMoveSound);
            }
          }
        }
      } else {
        setSelectedPiece(null);
        setLegalMoves([]);
      }
    } else {
      if (piece && isPieceMovable(piece, isWhiteTurn)) {
        setSelectedPiece({ row, col });
        const moves = generateLegalMoves(row, col, piece, board);
        const validMoves = filterOutMovesThatCauseCheck(
          row,
          col,
          moves,
          board,
          piece
        );

        if (piece.toLowerCase() === "k") {
          const castlingMoves = getCastlingMoves(
            row,
            col,
            isWhiteTurn,
            board,
            castlingRights
          );
          validMoves.push(...castlingMoves);
        }

        setLegalMoves(validMoves);
      }
    }
  };

  const handlePromotion = (newPiece) => {
    const { row, col, color } = promotion;
    if (typeof row !== "undefined" && typeof col !== "undefined") {
      // Check if row and col are defined
      const promotedPiece =
        color === "white" ? newPiece.toUpperCase() : newPiece.toLowerCase();
      const newBoard = board.map((r) => [...r]);

      // Place the promoted piece on the board at the correct rank
      newBoard[row][col] = promotedPiece;

      setBoard(newBoard); // Update the board state
      setPromotion(null); // Close the promotion modal
      setIsWhiteTurn(!isWhiteTurn); // Switch turn after promotion

      // Make sure to clear any selected piece state
      setSelectedPiece(null);
      setLegalMoves([]);
    }
  };

  const handleStartGame = () => {
    setGameStarted(true);
    setIsWhiteTurn(selectedColor === "white"); // Start based on selected color

    if (selectedColor === "black") {
      // AI makes the first move if the player chooses black
      setTimeout(handleAIMove, 500);
    }
  };

  const resetGame = () => {
    setBoard(initialBoardSetup);
    setSelectedPiece(null);
    setLegalMoves([]);
    setIsWhiteTurn(true);
    setInCheck({ white: false, black: false });
    setCheckmate(false);
    setWinner(null);
    setGameStarted(false);
    setPromotion(null); // Reset promotion state
    setCastlingRights({
      whiteKingSide: true,
      whiteQueenSide: true,
      blackKingSide: true,
      blackQueenSide: true,
    });
  };

  return (
    <div className="board-container">
      {!gameStarted && (
        <div className="start-game">
          {/* Existing code for color selection */}
          <label>
            Pick your difficulty:
            <select
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
            >
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>
          </label>
          <button className="start-button" onClick={handleStartGame}>
            Start New Game vs Computer
          </button>
        </div>
      )}

      {gameStarted && (
        <div
          className={`board ${selectedColor === "black" ? "invert-board" : ""}`}
        >
          {board.map((row, rowIndex) =>
            row.map((piece, colIndex) => (
              <Square
                key={`${rowIndex}-${colIndex}`}
                row={selectedColor === "white" ? rowIndex : 7 - rowIndex}
                col={selectedColor === "white" ? colIndex : 7 - colIndex}
                piece={piece}
                isHighlighted={legalMoves.some(
                  (move) => move[0] === rowIndex && move[1] === colIndex
                )}
                inCheck={
                  (inCheck.white && piece === "K") ||
                  (inCheck.black && piece === "k")
                }
                onClick={() => handleSquareClick(rowIndex, colIndex)}
              />
            ))
          )}
        </div>
      )}
      <div className="player-info">
        <div className="player-info">
          <p>Player 1: {userEmail}</p> {/* Display the fetched user email */}
        </div>
      </div>
      {gameStarted && (
        <button className="restart-button" onClick={resetGame}>
          Restart Game
        </button>
      )}

      {checkmate &&
        ReactDOM.createPortal(
          <GameOverModal winner={winner} onClose={resetGame} />,
          document.body
        )}

      {promotion &&
        ReactDOM.createPortal(
          <PromotionModal
            onPromote={handlePromotion}
            color={promotion.color}
          />,
          document.body
        )}
    </div>
  );
};

export default Board;
