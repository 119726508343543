import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  FaPlay,
  FaPause,
  FaStop,
  FaMinus,
  FaPlus,
  FaTrash,
  FaSave,
  FaTimes,
  FaVolumeUp,
  FaVolumeMute,
  FaUndo,
  FaRedo,
} from "react-icons/fa";
import InstrumentAudioContext from "./InstrumentAudioContext";
import { Client, Databases, ID, Query } from "appwrite";
import { useUser } from "./UserContext";
import "./css/Sequencer.css";
import MelodyModal from "./MelodyModal";
import PianoRollModal from "./PianoRollModal";
import { ClipLoader } from "react-spinners";
import genrePatterns from "./GenrePattern";
import client from "./appwriteConfig";
import { debounce } from "lodash";
import { usePatternInsSeq } from "./PatternInsSeqContext";

// Initialize Appwrite client
const databases = new Databases(client);

const generateNotes = () => {
  const notes = [];
  const noteNames = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  for (let octave = 0; octave < 5; octave++) {
    for (const note of noteNames) {
      notes.push(`${note}${octave}`);
    }
  }
  return notes.reverse(); // Reverse to start from C0 at the bottom
};

const Sequencer = ({
  pads,
  sequence,
  setSequence,
  togglePlay,
  bpm,
  setBpm,
  updateInstruments,
  transformedData,
}) => {
  const [pianoRollPatternNo, setPianoRollPatternNo] = useState(null);
  const { user } = useUser();
  const [selectedPattern, setSelectedPattern] = useState("initial");
  const [selectedInstruments, setSelectedInstruments] = useState([
    { InsName: "Piano", PianoRollPatternNo: "", InsVolume: 0.5 },
  ]);

  const [sequences, setSequences] = useState(
    pads.map(() => Array(16).fill(false))
  );
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [kitMuteStates, setKitMuteStates] = useState(
    pads.reduce((acc, pad) => {
      acc[pad.kitUrl] = false;
      return acc;
    }, {})
  );

  const [instrumentMuteStates, setInstrumentMuteStates] = useState(
    selectedInstruments.reduce((acc, instrument) => {
      acc[instrument.InsName] = false;
      return acc;
    }, {})
  );
  const [selectedGenre, setSelectedGenre] = useState("");

  const intervalRef = useRef(null);
  const stepsRefs = useRef(pads.map(() => React.createRef()));
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentStep, setCurrentStep] = useState(-1);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [kitVolumes, setKitVolumes] = useState({});
  const {
    fetchBeatPatterns,
    beatPatterns,
    setBeatPatterns,
    saveBeatPattern,
    deleteBeatPattern,
  } = usePatternInsSeq();

  const [selectedInstrument, setSelectedInstrument] = useState("");
  const [modalInstrument, setModalInstrument] = useState(null);
  const [pianoRollInstrument, setPianoRollInstrument] = useState(null);
  const [gridState, setGridState] = useState({});
  const [instrumentVolumes, setInstrumentVolumes] = useState({});
  const [isVolumeChanging, setIsVolumeChanging] = useState(false);
  const notes = generateNotes();
  const [currentBar, setCurrentBar] = useState(0);
  const [melodyInstrumentPatternNo, setMelodyInstrumentPatternNo] =
    useState(null);
  const globalIntervalRef = useRef(null);
  const [isGlobalPlaying, setIsGlobalPlaying] = useState(false);
  const [virtualGrids, setVirtualGrids] = useState({});
  const {
    pianoRollPatterns,
    setPianoRollPatterns,
    fetchPianoRollPatterns,
    fetchNotesPianoRoll,
  } = usePatternInsSeq();
  const [kitButtonActive, setKitButtonActive] = useState(null);

  const instruments = [
    "Piano",
    "String",
    "Pads",
    "Pluck",
    "Electric Piano",
    "Guitar",
    "Voice",
    "Flute",
    "Saxophone",
    "Clarinet",
    "Trumpet",
    "Brass",
  ];
  useEffect(() => {
    if (transformedData) {
      const newSequences = pads.map((pad) => {
        const kitName = pad.kitName.toLowerCase();
        return transformedData[kitName]
          ? transformedData[kitName].sequence
          : Array(16).fill(false);
      });
      setSequences(newSequences);
    }
  }, [transformedData]);
  useEffect(() => {
    setInstrumentMuteStates(
      selectedInstruments.reduce((acc, instrument) => {
        acc[instrument.InsName] = false;
        return acc;
      }, {})
    );
  }, [selectedInstruments]);
  useEffect(() => {
    const fetchPatterns = async () => {
      const patterns = await fetchPianoRollPatterns();
      setPianoRollPatterns(patterns);
    };
    fetchPatterns();
  }, []);

  useEffect(() => {
    if (user) {
      fetchBeatPatterns();
    }
  }, [user]);

  useEffect(() => {
    InstrumentAudioContext.setBpm(bpm);
  }, [bpm]);

  useEffect(() => {
    const loadAudioForInstruments = async () => {
      for (const instrument of selectedInstruments) {
        await InstrumentAudioContext.loadAudio(instrument.InsName);
      }
    };

    loadAudioForInstruments();
  }, [selectedInstruments]);

  useEffect(() => {
    selectedInstruments.forEach((instrument) => {
      InstrumentAudioContext.registerVolumeChangeListener(
        instrument.InsName,
        (newVolume) => {
          setInstrumentVolumes((prevVolumes) => ({
            ...prevVolumes,
            [instrument.InsName]: newVolume,
          }));
        }
      );
    });
  }, [selectedInstruments]);

  useEffect(() => {
    pads.forEach((pad) => {
      InstrumentAudioContext.registerVolumeChangeListener(
        pad.kitUrl,
        (newVolume) => {
          setKitVolumes((prevVolumes) => ({
            ...prevVolumes,
            [pad.kitUrl]: newVolume,
          }));
        }
      );
    });
  }, [pads]);

  useEffect(() => {
    pads.forEach((pad) => {
      InstrumentAudioContext.preloadAudio(pad.kitUrl);
    });
  }, [pads]);

  useEffect(() => {
    selectedInstruments.forEach((instrument) => {
      InstrumentAudioContext.stopAllNotes();
    });

    return () => {
      InstrumentAudioContext.stopAllNotes();
    };
  }, [selectedInstruments, bpm]);

  useEffect(() => {
    const preloadAllAudio = async () => {
      // Preload drum kit audio
      await Promise.all(
        pads.map((pad) => InstrumentAudioContext.preloadAudio(pad.kitUrl))
      );

      // Preload instrument audio
      await Promise.all(
        selectedInstruments.map((instrument) =>
          InstrumentAudioContext.loadAudio(instrument.InsName)
        )
      );
    };

    preloadAllAudio();
  }, [pads, selectedInstruments]);
  const handleInstrumentChangeFromDropdown = (
    currentInstrument,
    newInstrument
  ) => {
    setSelectedInstruments((prevInstruments) => {
      const updatedInstruments = prevInstruments.map((inst) =>
        inst.InsName === currentInstrument
          ? { ...inst, InsName: newInstrument }
          : inst
      );

      return updatedInstruments;
    });
    InstrumentAudioContext.loadAudio(newInstrument);
  };

  const handleKitButtonClick = (kitIndex) => {
    if (kitButtonActive === kitIndex) {
      setKitButtonActive(null);
    } else {
      if (kitButtonActive !== null) {
        const newSequences = [...sequences];
        newSequences[kitIndex] = [...sequences[kitButtonActive]];
        addToHistory(newSequences);
      }
      setKitButtonActive(kitIndex);
    }
  };

  const toggleBeat = (padIndex, beatIndex) => {
    setSequences((prevSequences) => {
      const newSequences = prevSequences.map((sequence, index) =>
        index === padIndex
          ? sequence.map((beat, idx) => (idx === beatIndex ? !beat : beat))
          : sequence
      );
      addToHistory(newSequences); // Add to history for undo/redo
      return newSequences;
    });
  };

  const fetchNotesAndSetGrid = async (instrument, patternNo) => {
    const notesArray = await fetchNotesPianoRoll(patternNo);

    const newGrid = Array(16)
      .fill()
      .map(() => Array(notes.length).fill(false));

    notesArray.forEach((note) => {
      const [noteName, bar] = note.split(",");
      const rowIndex = notes.findIndex((n) => n === noteName);
      const colIndex = parseInt(bar, 10) - 1;
      if (rowIndex !== -1 && colIndex >= 0 && colIndex < 16) {
        newGrid[colIndex][rowIndex] = true;
      }
    });

    setGridState((prevGrid) => ({
      ...prevGrid,
      [instrument.InsName]: newGrid,
    }));
  };

  const handleUserPatternChange = async (event) => {
    const patternId = event.target.value;
    setSelectedPattern(patternId);

    if (patternId === "initial") {
      setSequences(pads.map(() => Array(16).fill(false)));
      setKitVolumes(
        pads.reduce((acc, pad) => {
          acc[pad.kitUrl] = 0.5;
          return acc;
        }, {})
      );
      pads.forEach((pad) => {
        InstrumentAudioContext.setKitVolume(0.5, pad.kitUrl);
      });
      setSelectedInstruments([]); // Reset instruments
      setVirtualGrids({});
      return;
    }

    try {
      const response = await databases.getDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_BEAT_PATTERN_ID,
        patternId
      );
      const parsedKits = JSON.parse(response.kitsJson);
      let parsedInstruments;

      try {
        parsedInstruments = JSON.parse(response.instrumentsJson || "{}");
        parsedInstruments = Object.values(parsedInstruments);
      } catch (parseError) {
        console.error("Failed to parse instrumentsJson:", parseError);
        parsedInstruments = [];
      }

      if (!Array.isArray(parsedInstruments)) {
        console.error("parsedInstruments is not an array:", parsedInstruments);
        parsedInstruments = [];
      }

      const newKitVolumes = {};
      const newSequences = pads.map((pad, index) => {
        const kitName = pad.kitName || `pad-${index}`;
        const kitData = parsedKits[kitName] || {
          sequence: Array(16).fill(0),
          kitVolume: 0.5,
        };
        const volume =
          kitData.kitVolume !== undefined ? kitData.kitVolume : 0.5;
        InstrumentAudioContext.setKitVolume(volume, pad.kitUrl);
        newKitVolumes[pad.kitUrl] = volume;
        return kitData.sequence.map((beat) => beat === 1);
      });

      setSequences(newSequences);
      setKitVolumes(newKitVolumes);

      // Load audio and set instruments
      const loadedInstruments = await Promise.all(
        parsedInstruments.map(async (instrument) => {
          await InstrumentAudioContext.loadAudio(instrument.InsName);
          await fetchNotesAndSetGrid(instrument, instrument.PianoRollPatternNo);
          return instrument;
        })
      );

      setSelectedInstruments(loadedInstruments);
      // Update virtual grids
      const newVirtualGrids = {};
      loadedInstruments.forEach((instrument) => {
        const grid = gridState[instrument.InsName];
        if (grid) {
          newVirtualGrids[instrument.InsName] = grid;
        }
      });
      setVirtualGrids(newVirtualGrids);
      // Load audio for updated patterns
      loadAudioForUpdatedPatterns();
    } catch (error) {
      console.error("Failed to retrieve pattern:", error);
    }
  };

  useEffect(() => {
    stepsRefs.current.forEach((ref, index) => {
      if (ref.current) {
        ref.current.addEventListener("scroll", () => handleScroll(index));
      }
    });

    return () => {
      stepsRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.removeEventListener("scroll", handleScroll);
        }
      });
    };
  }, []);
  const handlePianoRollPatternChange = async (instrumentName, patternId) => {
    const updatedInstruments = selectedInstruments.map((inst) =>
      inst.InsName === instrumentName
        ? { ...inst, PianoRollPatternNo: patternId }
        : inst
    );
    setSelectedInstruments(updatedInstruments);

    const instrument = updatedInstruments.find(
      (inst) => inst.InsName === instrumentName
    );
    if (instrument) {
      const notesArray = await fetchNotesPianoRoll(patternId);

      const newGrid = Array(16)
        .fill()
        .map(() => Array(notes.length).fill(false));

      notesArray.forEach((note) => {
        const [noteName, bar] = note.split(",");
        const rowIndex = notes.findIndex((n) => n === noteName);
        const colIndex = parseInt(bar, 10) - 1;
        if (rowIndex !== -1 && colIndex >= 0 && colIndex < 16) {
          newGrid[colIndex][rowIndex] = true;
        }
      });

      setGridState((prevGrid) => ({
        ...prevGrid,
        [instrumentName]: newGrid,
      }));

      // Update the virtual grids immediately for real-time playback
      setVirtualGrids((prevGrids) => ({
        ...prevGrids,
        [instrumentName]: newGrid,
      }));
    }

    // Fetch the latest beat patterns
    await fetchBeatPatterns();
    // Load audio for updated beat patterns
    loadAudioForUpdatedPatterns();
  };

  // New useEffect hook to watch for changes in selectedPattern and selectedInstruments
  useEffect(() => {
    if (selectedPattern && selectedPattern !== "initial") {
      const fetchAndSetVirtualGrids = async () => {
        const newVirtualGrids = {};
        for (const instrument of selectedInstruments) {
          const notesArray = await fetchNotesPianoRoll(
            instrument.PianoRollPatternNo
          );
          const newGrid = Array(16)
            .fill()
            .map(() => Array(notes.length).fill(false));
          notesArray.forEach((note) => {
            const [noteName, bar] = note.split(",");
            const rowIndex = notes.findIndex((n) => n === noteName);
            const colIndex = parseInt(bar, 10) - 1;
            if (rowIndex !== -1 && colIndex >= 0 && colIndex < 16) {
              newGrid[colIndex][rowIndex] = true;
            }
          });
          newVirtualGrids[instrument.InsName] = newGrid;
        }
        setVirtualGrids(newVirtualGrids);
      };
      fetchAndSetVirtualGrids();
    }
  }, [selectedPattern, selectedInstruments]);

  const handleScroll = (idx) => {
    const scrollPosition = stepsRefs.current[idx].current.scrollLeft;
    stepsRefs.current.forEach((ref, index) => {
      if (index !== idx && ref.current) {
        ref.current.scrollLeft = scrollPosition;
      }
    });
  };
  useEffect(() => {
    if (selectedPattern && selectedPattern !== "initial") {
      const fetchAndSetVirtualGrids = async () => {
        const newVirtualGrids = {};
        for (const instrument of selectedInstruments) {
          const notesArray = await fetchNotesPianoRoll(
            instrument.PianoRollPatternNo
          );
          const newGrid = Array(16)
            .fill()
            .map(() => Array(notes.length).fill(false));
          notesArray.forEach((note) => {
            const [noteName, bar] = note.split(",");
            const rowIndex = notes.findIndex((n) => n === noteName);
            const colIndex = parseInt(bar, 10) - 1;
            if (rowIndex !== -1 && colIndex >= 0 && colIndex < 16) {
              newGrid[colIndex][rowIndex] = true;
            }
          });
          newVirtualGrids[instrument.InsName] = newGrid;
        }
        setVirtualGrids(newVirtualGrids);
      };
      fetchAndSetVirtualGrids();
    }
  }, [selectedPattern, selectedInstruments]);
  useEffect(() => {
    if (isPlaying || isGlobalPlaying) {
      const interval = (60 / bpm) * 250;
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        setCurrentStep((prevStep) => {
          const nextStep = (prevStep + 1) % 16;

          // Trigger drum sounds
          sequences.forEach((sequence, padIndex) => {
            if (sequence[nextStep]) {
              // console.log(
              //   `Beat pattern: ${nextStep}, Drum pad: ${pads[padIndex].type}`
              // );
              InstrumentAudioContext.playSound(pads[padIndex].kitUrl);
            }
          });

          // Trigger all instrument notes at the current step
          playAllInstrumentsAtCurrentBar(nextStep);

          return nextStep;
        });
      }, interval);
    } else {
      clearInterval(intervalRef.current);
      InstrumentAudioContext.stopAllNotes();
    }
    return () => clearInterval(intervalRef.current);
  }, [
    isPlaying,
    isGlobalPlaying,
    bpm,
    sequences,
    selectedInstruments,
    virtualGrids,
  ]);
  // const handlePlayClick = () => {
  //   setIsPlaying((prev) => {
  //     const newIsPlaying = !prev;
  //     if (newIsPlaying && !isGlobalPlaying) {
  //       handleGlobalPlayPause();
  //     } else if (!newIsPlaying && isGlobalPlaying) {
  //       handleGlobalPlayPause();
  //     }
  //     return newIsPlaying;
  //   });
  // };

  // const handleStopClick = () => {
  //   setIsPlaying(false);
  //   if (isGlobalPlaying) {
  //     handleGlobalPlayPause();
  //   }
  //   setCurrentStep(-1);
  // };

  const increaseBpm = () => setBpm((bpm) => (bpm + 5 > 300 ? 300 : bpm + 5));
  const decreaseBpm = () => setBpm((bpm) => (bpm - 5 < 20 ? 20 : bpm - 5));

  const genreKeys = Object.keys(genrePatterns);
  const handleSavePattern = async () => {
    const newPatternId = await saveBeatPattern(
      sequences,
      pads,
      selectedPattern,
      beatPatterns,
      setBeatPatterns,
      fetchBeatPatterns,
      user,
      setIsSaving,
      selectedInstruments, // Pass selectedInstruments
      instrumentVolumes // Pass instrumentVolumes
    );
    setSelectedPattern(newPatternId);
  };

  const handleDeletePattern = () => {
    deleteBeatPattern(
      selectedPattern,
      beatPatterns,
      setBeatPatterns,
      setSelectedPattern,
      setSequences,
      pads,
      user,
      setIsDeleting
    );
  };

  const handleKitVolumeChange = (kitId) => (e) => {
    const volume = parseFloat(e.target.value);
    setKitVolumes((prevVolumes) => ({
      ...prevVolumes,
      [kitId]: volume,
    }));
    InstrumentAudioContext.setKitVolume(volume, kitId);
  };

  const handleInstrumentChange = async (event) => {
    const instrument = event.target.value;
    setSelectedInstrument(instrument);
    if (instrument) {
      await InstrumentAudioContext.loadAudio(instrument);
    }
  };

  const addInstrument = async () => {
    if (
      selectedInstrument &&
      !selectedInstruments.some((inst) => inst.InsName === selectedInstrument)
    ) {
      await InstrumentAudioContext.loadAudio(selectedInstrument);
      setSelectedInstruments([
        { InsName: selectedInstrument, PianoRollPatternNo: "", InsVolume: 0.5 },
        ...selectedInstruments,
      ]);
      InstrumentAudioContext.setInstVolume(0.5, selectedInstrument); // Set initial volume
      setInstrumentVolumes((prevVolumes) => ({
        ...prevVolumes,
        [selectedInstrument]: 0.5,
      }));
    }
  };

  const debouncedSetVolume = useCallback(
    debounce((volume, instrument) => {
      InstrumentAudioContext.setInstVolume(volume, instrument);
      setIsVolumeChanging(false);
    }, 100),
    []
  );

  const handleVolumeChange = (instrument) => (e) => {
    const volume = parseFloat(e.target.value);
    setInstrumentVolumes((prevVolumes) => ({
      ...prevVolumes,
      [instrument]: volume,
    }));
    setIsVolumeChanging(true);
    debouncedSetVolume(volume, instrument);
  };

  const removeInstrument = (instrument) => {
    setSelectedInstruments(
      selectedInstruments.filter((inst) => inst.InsName !== instrument)
    );
  };

  const openMelodyModal = (instrument) => {
    setModalInstrument(instrument);
    const selectedInstrument = selectedInstruments.find(
      (inst) => inst.InsName === instrument
    );
    const patternNo = selectedInstrument
      ? selectedInstrument.PianoRollPatternNo
      : null;
    setMelodyInstrumentPatternNo(patternNo);
  };

  const closeMelodyModal = async (newPatternNo) => {
    setModalInstrument(null);

    if (newPatternNo) {
      const updatedInstruments = selectedInstruments.map((inst) =>
        inst.InsName === modalInstrument
          ? { ...inst, PianoRollPatternNo: newPatternNo }
          : inst
      );
      setSelectedInstruments(updatedInstruments);
    }

    // Fetch and set the new patterns
    const pianoRollPatterns = await fetchPianoRollPatterns();
    setPianoRollPatterns(pianoRollPatterns);

    if (newPatternNo) {
      const updatedInstruments = selectedInstruments.map((inst) =>
        inst.InsName === modalInstrument
          ? { ...inst, PianoRollPatternNo: newPatternNo }
          : inst
      );
      setSelectedInstruments(updatedInstruments);
    }

    // Fetch the latest beat patterns
    await fetchBeatPatterns();
    // Load audio for updated beat patterns
    loadAudioForUpdatedPatterns();
  };

  const openPianoRollModal = (instrument) => {
    const selectedInstrument = selectedInstruments.find(
      (inst) => inst.InsName === instrument
    );
    const patternNo = selectedInstrument
      ? selectedInstrument.PianoRollPatternNo
      : null;
    setPianoRollInstrument(instrument); // Only set the instrument name
    setPianoRollPatternNo(patternNo); // Separate state for the pattern number
  };

  const closePianoRollModal = async (updatedGrid, newPatternNo) => {
    setPianoRollInstrument(null);
    if (updatedGrid) {
      setGridState((prevGrid) => ({
        ...prevGrid,
        [pianoRollInstrument]: updatedGrid,
      }));
    }

    // Fetch and set the new patterns
    const pianoRollPatterns = await fetchPianoRollPatterns();
    setPianoRollPatterns(pianoRollPatterns);

    if (newPatternNo) {
      const updatedInstruments = selectedInstruments.map((inst) =>
        inst.InsName === pianoRollInstrument
          ? { ...inst, PianoRollPatternNo: newPatternNo }
          : inst
      );
      setSelectedInstruments(updatedInstruments);
    }

    // Fetch the latest beat patterns
    await fetchBeatPatterns();
    // Load audio for updated beat patterns
    loadAudioForUpdatedPatterns();
  };
  const loadAudioForUpdatedPatterns = async () => {
    for (const instrument of selectedInstruments) {
      await InstrumentAudioContext.loadAudio(instrument.InsName);
    }
  };
  const handleGlobalPlayPause = () => {
    setIsGlobalPlaying((prev) => {
      const newIsGlobalPlaying = !prev;
      if (newIsGlobalPlaying) {
        // Ensure virtualGrids are set for all instruments
        const newVirtualGrids = {};
        selectedInstruments.forEach((instrument) => {
          const grid = gridState[instrument.InsName];
          if (grid) {
            newVirtualGrids[instrument.InsName] = grid;
          }
        });
        setVirtualGrids(newVirtualGrids);
        loadAudioForUpdatedPatterns(); // Load audio for all instruments
      } else {
        InstrumentAudioContext.stopAllNotes();
      }
      return newIsGlobalPlaying;
    });
  };

  useEffect(() => {
    if (isGlobalPlaying) {
      const interval = (60 / bpm) * 250; // Adjust interval calculation to ensure correct timing
      globalIntervalRef.current = setInterval(() => {
        setCurrentBar((prevBar) => (prevBar + 1) % 16);
      }, interval);
    } else {
      clearInterval(globalIntervalRef.current);
      InstrumentAudioContext.stopAllNotes();
    }

    return () => clearInterval(globalIntervalRef.current);
  }, [isGlobalPlaying, bpm]);

  const playAllInstrumentsAtCurrentBar = (bar) => {
    selectedInstruments.forEach((instrument) => {
      const grid = virtualGrids[instrument.InsName] || [];
      if (grid[bar]) {
        grid[bar].forEach((active, noteIndex) => {
          if (active) {
            const adjustedNoteIndex = notes.length - 1 - noteIndex;
            InstrumentAudioContext.playPianoRollNote(
              adjustedNoteIndex,
              notes,
              instrument.InsName
            );
          }
        });
      }
    });
  };

  const handleGlobalStop = () => {
    setIsGlobalPlaying(false);
    setCurrentBar(0);
    setCurrentStep(-1);
    InstrumentAudioContext.stopAllNotes();
  };
  const handleKitMuteToggle = (kitId) => {
    setKitMuteStates((prevStates) => {
      const newState = !prevStates[kitId];
      InstrumentAudioContext.setMute(newState, kitId);
      return { ...prevStates, [kitId]: newState };
    });
  };

  const handleInstrumentMuteToggle = (instrumentName) => {
    setInstrumentMuteStates((prevStates) => {
      const newState = !prevStates[instrumentName];
      InstrumentAudioContext.setMute(newState, instrumentName);
      return { ...prevStates, [instrumentName]: newState };
    });
  };
  const handleToggleAllKitsMute = () => {
    setKitMuteStates((prevStates) => {
      const newStates = {};
      const newStateValue = !Object.values(prevStates).every(Boolean);
      pads.forEach((pad) => {
        InstrumentAudioContext.setMute(newStateValue, pad.kitUrl);
        newStates[pad.kitUrl] = newStateValue;
      });
      return newStates;
    });
  };

  const handleToggleAllInstrumentsMute = () => {
    setInstrumentMuteStates((prevStates) => {
      const newStates = {};
      const newStateValue = !Object.values(prevStates).every(Boolean);
      selectedInstruments.forEach((instrument) => {
        InstrumentAudioContext.setMute(newStateValue, instrument.InsName);
        newStates[instrument.InsName] = newStateValue;
      });
      return newStates;
    });
  };
  const addToHistory = (newSequences) => {
    setHistory((prevHistory) => [...prevHistory, sequences]);
    setRedoStack([]);
    setSequences(newSequences);
  };

  const undo = () => {
    if (history.length > 0) {
      const previousSequences = history[history.length - 1];
      setHistory((prevHistory) => prevHistory.slice(0, -1));
      setRedoStack((prevRedoStack) => [sequences, ...prevRedoStack]);
      setSequences(previousSequences);
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const nextSequences = redoStack[0];
      setRedoStack((prevRedoStack) => prevRedoStack.slice(1));
      setHistory((prevHistory) => [...prevHistory, sequences]);
      setSequences(nextSequences);
    }
  };

  return (
    <div className="sequencer">
      <div className="controls-seq">
        <div className="playback-controls-ins">
          <button
            onClick={handleGlobalPlayPause}
            className="play-button-pianoroll"
          >
            {isGlobalPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button onClick={handleGlobalStop} className="stop-button2">
            <FaStop />
          </button>
        </div>
        <div className="bpm-controls">
          <button onClick={decreaseBpm} className="bpm-button">
            <FaMinus />
          </button>
          <span className="bpm-display">{bpm} BPM</span>
          <button onClick={increaseBpm} className="bpm-button">
            <FaPlus />
          </button>
        </div>{" "}
        <button onClick={undo} className="undo-button">
          <FaUndo />
        </button>
        <button onClick={redo} className="redo-button">
          <FaRedo />
        </button>
      </div>

      <div className="drum-header1">
        <h3>DRUMS</h3>{" "}
        <div className="sorting-group">
          {/* <select
            className="instrument-dropdown"
            onChange={handleGenreChange}
            value={selectedGenre}
          >
            <option value="">Select Genre</option>
            {genreKeys.map((genre) => (
              <option key={genre} value={genre}>
                {genre}
              </option>
            ))}
          </select> */}
          <select
            className="instrument-dropdown"
            onChange={handleUserPatternChange}
            value={selectedPattern}
          >
            <option value="initial">Select Pattern</option>
            {beatPatterns.map((pattern) => (
              <option key={pattern.$id} value={pattern.$id}>
                Pattern {pattern.patternNumber}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={handleSavePattern}
          className="save-pattern-button"
          style={{ cursor: isSaving ? "wait" : "pointer" }}
          disabled={isSaving}
        >
          {isSaving ? <ClipLoader size={20} color="#fff" /> : <FaSave />}
        </button>
        <button onClick={handleDeletePattern} className="delete-pattern-button">
          {isDeleting ? <ClipLoader size={20} color="#fff" /> : <FaTrash />}
        </button>{" "}
        <button onClick={handleToggleAllKitsMute} className="mute-button">
          {Object.values(kitMuteStates).every(Boolean) ? (
            <FaVolumeMute className="mute" />
          ) : (
            <FaVolumeUp className="mute-no" />
          )}
        </button>
      </div>

      {pads.map((pad, index) => (
        <div key={pad.$id} className="track1">
          <button
            className={`kit-name ${kitButtonActive === index ? "active" : ""}`}
            onClick={() => handleKitButtonClick(index)}
          >
            {pad.kitName || "Unnamed Kit"}
          </button>
          <div className="steps" ref={stepsRefs.current[index]}>
            {sequences[index].map((active, beatIndex) => (
              <button
                key={beatIndex}
                className={`beat ${active ? "active" : ""} ${
                  currentStep === beatIndex ? "current" : ""
                }`}
                onClick={() => toggleBeat(index, beatIndex)}
              />
            ))}
          </div>
          <div className="kit-volume-control">
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={kitVolumes[pad.kitUrl] ?? 0.5} // Ensure default value is provided
              onChange={handleKitVolumeChange(pad.kitUrl)}
              className="volume-slider-seq"
            />
            <button
              onClick={() => handleKitMuteToggle(pad.kitUrl)}
              className="mute-button"
            >
              {kitMuteStates[pad.kitUrl] ? (
                <FaVolumeMute className="mute" />
              ) : (
                <FaVolumeUp className="mute-no" />
              )}
            </button>
          </div>
        </div>
      ))}

      <div className="instru-header">
        {/* <div className="bpm-controls">
          <button onClick={decreaseBpm} className="bpm-button">
            <FaMinus />
          </button>
          <span className="bpm-display">{bpm} BPM</span>
          <button onClick={increaseBpm} className="bpm-button">
            <FaPlus />
          </button>
        </div> */}

        <div className="sel-ins-p1">
          {" "}
          <div className="drum-header1">
            <h3>INSTRUMENTS</h3>{" "}
            <select
              className="instrument-dropdown"
              value={selectedInstrument}
              onChange={handleInstrumentChange}
            >
              <option value="">Select</option>
              {instruments.map((instrument) => (
                <option key={instrument} value={instrument}>
                  {instrument}
                </option>
              ))}
            </select>
            <button onClick={addInstrument} className="add-instrument-button">
              <FaPlus />
            </button>{" "}
            <button
              onClick={handleToggleAllInstrumentsMute}
              className="mute-button"
            >
              {Object.values(instrumentMuteStates).every(Boolean) ? (
                <FaVolumeMute className="mute" />
              ) : (
                <FaVolumeUp className="mute-no" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="selected-instruments">
        {selectedInstruments.map((instrument) => (
          <div key={instrument.InsName} className="selected-instrument">
            <div className="eachins-top">
              <div className="instrument-name">
                <select
                  className="instrument-dropdown"
                  onChange={(e) =>
                    handleInstrumentChangeFromDropdown(
                      instrument.InsName,
                      e.target.value
                    )
                  }
                  value={instrument.InsName}
                >
                  {instruments.map((inst) => (
                    <option key={inst} value={inst}>
                      {inst}
                    </option>
                  ))}
                </select>
              </div>
              <div className="make-melody-button-container">
                <button
                  className="make-melody-button"
                  onClick={() => openMelodyModal(instrument.InsName)}
                >
                  🎹
                </button>
              </div>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={instrumentVolumes[instrument.InsName] ?? 0.5} // Ensure default value is provided
                onChange={handleVolumeChange(instrument.InsName)}
                className="volume-slider"
              />{" "}
              <button
                onClick={() => handleInstrumentMuteToggle(instrument.InsName)}
                className="mute-button"
              >
                {instrumentMuteStates[instrument.InsName] ? (
                  <FaVolumeMute className="mute" />
                ) : (
                  <FaVolumeUp className="mute-no" />
                )}
              </button>
            </div>

            <div className="piano-roll-button-container">
              <select
                className="roll-pattern-dropdown"
                value={instrument.PianoRollPatternNo || ""}
                onChange={(e) =>
                  handlePianoRollPatternChange(
                    instrument.InsName,
                    e.target.value
                  )
                }
              >
                <option value="">Roll Pattern</option>
                {pianoRollPatterns.map((pattern) => (
                  <option key={pattern.$id} value={pattern.patternNo}>
                    Preset: {pattern.patternNo}
                  </option>
                ))}
              </select>
              <button
                className="piano-roll-button"
                onClick={() => openPianoRollModal(instrument.InsName)}
              >
                Notes 🎵
              </button>
              <div className="remove-button-container">
                <button
                  onClick={() => removeInstrument(instrument.InsName)}
                  className="remove-instrument-button"
                >
                  <FaTimes />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {modalInstrument && (
        <MelodyModal
          instrument={modalInstrument}
          onClose={closeMelodyModal}
          bpm={bpm}
          setBpm={setBpm}
          selectedInstruments={selectedInstruments}
          setSelectedInstruments={setSelectedInstruments}
          initialPatternNo={melodyInstrumentPatternNo}
        />
      )}
      {pianoRollInstrument && (
        <PianoRollModal
          instrument={pianoRollInstrument}
          initialPatternNo={pianoRollPatternNo}
          onClose={closePianoRollModal}
          bpm={bpm}
          setBpm={setBpm}
          selectedInstruments={selectedInstruments}
          setSelectedInstruments={setSelectedInstruments}
        />
      )}
    </div>
  );
};

export default Sequencer;
