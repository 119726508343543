import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Storage } from "appwrite";
import client from "./appwriteConfig";
import "./css/Redirect.css";

const Redirect = () => {
  const location = useLocation();
  const [audioDownloadUrls, setAudioDownloadUrls] = useState([]);
  const [videoDownloadUrls, setVideoDownloadUrls] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const audioUrls = params.get("audioUrls")
      ? params.get("audioUrls").split(",")
      : [];
    const videoUrls = params.get("videoUrls")
      ? params.get("videoUrls").split(",")
      : [];

    const fetchDownloadLinks = async () => {
      const storage = new Storage(client);

      const audioUrlsPromises = audioUrls.map(async (audioUrl) => {
        try {
          const file = await storage.getFileDownload(
            process.env.REACT_APP_APPWRITE_BUCKET_ID,
            audioUrl
          );
          return file.href;
        } catch (error) {
          console.error("Failed to fetch download link for", audioUrl, error);
          return null;
        }
      });

      const videoUrlsPromises = videoUrls.map(async (videoUrl) => {
        try {
          const file = await storage.getFileDownload(
            process.env.REACT_APP_TUTORIALS_BUCKET_ID,
            videoUrl
          );
          return file.href;
        } catch (error) {
          console.error("Failed to fetch download link for", videoUrl, error);
          return null;
        }
      });

      const audioUrlsResults = await Promise.all(audioUrlsPromises);
      const videoUrlsResults = await Promise.all(videoUrlsPromises);

      setAudioDownloadUrls(audioUrlsResults.filter((url) => url !== null));
      setVideoDownloadUrls(videoUrlsResults.filter((url) => url !== null));
    };

    if (audioUrls.length > 0 || videoUrls.length > 0) {
      fetchDownloadLinks();
    }
  }, [location.search]);

  return (
    <div className="redirect-container">
      <h2>Payment Successful!</h2>
      <p>Thank you for your purchase. You can download your products below:</p>
      {audioDownloadUrls.length > 0 && (
        <>
          <h3>Audio Files:</h3>
          {audioDownloadUrls.map((url, index) => (
            <a key={index} href={url} className="download-link">
              Download audio file {index + 1}
            </a>
          ))}
        </>
      )}
      {videoDownloadUrls.length > 0 && (
        <>
          <h3>Video Files:</h3>
          {videoDownloadUrls.map((url, index) => (
            <a key={index} href={url} className="download-link">
              Download video file {index + 1}
            </a>
          ))}
        </>
      )}
      {audioDownloadUrls.length === 0 && videoDownloadUrls.length === 0 && (
        <p>Loading download links...</p>
      )}
    </div>
  );
};

export default Redirect;
