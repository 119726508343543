import React, { useEffect, useState } from "react";
import { Client, Functions } from "appwrite";

const client = new Client();
client
  .setEndpoint("https://66e9a9ab7894df45f561.appwrite.global/v1")
  .setProject(process.env.REACT_APP_PROJECT_ID); // Replace with your project ID

const functions = new Functions(client);

const ActiveUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Call the server-side function to get all users
        const response = await functions.createExecution(
          "66e9a9ae0002784f36a3", // Replace with your function ID
          {},
          true // Include credentials
        );
        const result = JSON.parse(response.response); // Parse the response to get the list of users

        if (result.users) {
          setUsers(result.users);
          console.log("All Users:", result.users); // Log to console
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div>
      <h3>All Users</h3>
      <ul>
        {users.map((user) => (
          <li key={user.$id}>{user.email}</li>
        ))}
      </ul>
    </div>
  );
};

export default ActiveUsers;
