import React, { useRef, useState } from "react";
import { Client, Databases, Storage, ID } from "appwrite";
import WaveSurfer from "wavesurfer.js";

import client from "./appwriteConfig";
import "./css/Sellbeat.css";
import { useUser } from "./UserContext";

const SellBeat = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [artistName, setArtistName] = useState("");
  const [beatName, setBeatName] = useState("");
  const [genre, setGenre] = useState("");
  const [tempo, setTempo] = useState("");
  const [cost, setCost] = useState("");
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);

  const convertToLowQualityWav = async (audioFile) => {
    const arrayBuffer = await audioFile.arrayBuffer();
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const desiredSampleRate = 16400;
    const offlineContext = new OfflineAudioContext(
      audioBuffer.numberOfChannels,
      (audioBuffer.length * desiredSampleRate) / audioBuffer.sampleRate,
      desiredSampleRate
    );

    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;

    const biquadFilter = offlineContext.createBiquadFilter();
    biquadFilter.type = "highpass";
    biquadFilter.frequency.setValueAtTime(100, offlineContext.currentTime);

    source.connect(biquadFilter);
    biquadFilter.connect(offlineContext.destination);

    source.start(0);

    const renderedBuffer = await offlineContext.startRendering();
    const wavBlob = bufferToWave(renderedBuffer);
    return wavBlob;
  };

  const bufferToWave = (buffer) => {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2 + 44;
    const bufferArray = new ArrayBuffer(length);
    const view = new DataView(bufferArray);

    const channels = [];
    let sample;
    let offset = 0;
    let pos = 0;

    setUint32(0x46464952);
    setUint32(length - 8);
    setUint32(0x45564157);

    setUint32(0x20746d66);
    setUint32(16);
    setUint16(1);
    setUint16(numOfChan);
    setUint32(buffer.sampleRate);
    setUint32(buffer.sampleRate * 2 * numOfChan);
    setUint16(numOfChan * 2);
    setUint16(16);

    setUint32(0x61746164);
    setUint32(length - pos - 4);

    for (let i = 0; i < buffer.numberOfChannels; i++)
      channels.push(buffer.getChannelData(i));

    while (pos < length) {
      for (let i = 0; i < numOfChan; i++) {
        sample = Math.max(-1, Math.min(1, channels[i][offset]));
        sample = (sample < 0 ? sample * 32768 : sample * 32767) | 0;
        view.setInt16(pos, sample, true);
        pos += 2;
      }
      offset++;
    }

    return new Blob([bufferArray], { type: "audio/wav" });

    function setUint16(data) {
      view.setUint16(pos, data, true);
      pos += 2;
    }

    function setUint32(data) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
  };
  const capitalizeFirstLetterOfEachWord = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const storage = new Storage(client);

      // Upload audio file
      const audioFile = fileInputRef.current.files[0];
      if (audioFile.size > 50 * 1024 * 1024) {
        throw new Error(
          "The file size exceeds 50 MB. Please upload a smaller file."
        );
      }

      // Convert beatName and artistName to have the first letter of each word capitalized
      const capitalizedBeatName = capitalizeFirstLetterOfEachWord(beatName);
      const capitalizedArtistName = capitalizeFirstLetterOfEachWord(artistName);

      const audioFileName = `${capitalizedBeatName}_${capitalizedArtistName}.mp3`;
      const audioResponse = await storage.createFile(
        process.env.REACT_APP_BUCKET_BEATS_ID,
        ID.unique(),
        new File([audioFile], audioFileName, { type: "audio/mpeg" })
      );
      const audioUrl = audioResponse.$id;

      // Convert and upload low-quality audio file
      const lowQualityWavBlob = await convertToLowQualityWav(audioFile);
      const lowQualityFileName = `low_${capitalizedBeatName}_${capitalizedArtistName}.wav`;
      const lowQualityResponse = await storage.createFile(
        process.env.REACT_APP_BUCKET_BEATS_ID,
        ID.unique(),
        new File([lowQualityWavBlob], lowQualityFileName, { type: "audio/wav" })
      );
      const lowAudioUrl = lowQualityResponse.$id;

      // Upload image file
      const imageFile = imageInputRef.current.files[0];
      const imageFileType = imageFile.type.split("/")[1]; // Get the file extension
      const imageFileName = `${capitalizedBeatName}_${capitalizedArtistName}.${imageFileType}`;
      const imageResponse = await storage.createFile(
        process.env.REACT_APP_BUCKET_BEATS_ID,
        ID.unique(),
        new File([imageFile], imageFileName, { type: imageFile.type })
      );
      const imageUrl = imageResponse.$id;

      // Create a document with artist name, beat name, genre, tempo, cost,
      // and audio/image file IDs
      const database = new Databases(client);
      const productId = ID.unique();
      const productData = {
        artistName: capitalizedArtistName,
        beatName: capitalizedBeatName,
        genre,
        tempo,
        email: user.email,
        cost: parseInt(cost, 10), // Ensure cost is an integer
        imageUrl,
        audioUrl,
        lowAudioUrl,
      };
      const response = await database.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_BEATS_ID,
        productId,
        productData
      );
      console.log(response);

      setLoading(false);
      setArtistName("");
      setBeatName("");
      setGenre("");
      setTempo("");
      setCost("");
      fileInputRef.current.value = null;
      imageInputRef.current.value = null;
    } catch (error) {
      console.error("Error uploading files:", error);
      setError("Error uploading files: " + error.message);
      setLoading(false);
    }
  };

  return (
    <div className="sell-beats-container">
      <h2>Sell Your Beat</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Artist Name:</label>
          <input
            type="text"
            value={artistName}
            onChange={(e) => setArtistName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Beat Name:</label>
          <input
            type="text"
            value={beatName}
            onChange={(e) => setBeatName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Genre:</label>
          <input
            type="text"
            value={genre}
            onChange={(e) => setGenre(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Tempo:</label>
          <input
            type="text"
            value={tempo}
            onChange={(e) => setTempo(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Cost:</label>
          <input
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Upload Audio File:</label>
          <input type="file" ref={fileInputRef} accept="audio/*" required />
        </div>
        <div className="form-group">
          <label>Upload Image File:</label>
          <input type="file" ref={imageInputRef} accept="image/*" required />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Uploading..." : "Submit"}
        </button>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
};

export default SellBeat;
