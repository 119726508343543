import React, { useState } from "react";
import { Client, Databases, ID, Storage } from "appwrite";
import "./css/SellMerch.css"; // Ensure you create this CSS file for styling
import { useUser } from "./UserContext";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import imageCompression from "browser-image-compression"; // Import image compression

const client = new Client()
  .setEndpoint("https://cloud.appwrite.io/v1")
  .setProject(process.env.REACT_APP_PROJECT_ID);

const databases = new Databases(client);
const storage = new Storage(client);

const SellMerch = () => {
  const { user } = useUser();
  const [brand, setBrand] = useState("");
  const [itemName, setItemName] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [category, setCategory] = useState("");
  const [sizes, setSizes] = useState("");
  const [colors, setColors] = useState("");
  const [material, setMaterial] = useState("");
  const [stock, setStock] = useState("");
  const [isMultiple, setIsMultiple] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const uploadedFiles = [];

      for (const file of files) {
        let fileToUpload = file;

        // Check if the file size is greater than 1MB
        if (file.size > 1048576) {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920, // optional, can be adjusted based on image dimensions
            useWebWorker: true,
          };
          fileToUpload = await imageCompression(file, options);
        }

        const prefixedFileName = `${brand.replace(/ /g, "_")}_${
          fileToUpload.name
        }`;
        const response = await storage.createFile(
          process.env.REACT_APP_BUCKET_FASHION_ID,
          ID.unique(),
          new File([fileToUpload], prefixedFileName, {
            type: fileToUpload.type,
          })
        );
        uploadedFiles.push(response.$id);
      }

      for (const fileId of uploadedFiles) {
        const itemId = ID.unique();
        const itemData = {
          brand,
          itemId: `item_${Date.now()}`,
          itemName,
          description,
          cost: parseFloat(cost), // Ensure cost is a number
          category,
          email: user.email,
          sizes: sizes.toString(),
          colors: colors.toString(),
          material,
          stock: parseInt(stock, 10), // Convert stock to an integer
          fashionUrl: fileId,
        };

        await databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_FASHION_ID,
          itemId,
          itemData
        );
      }

      // Reset form fields
      setBrand("");
      setItemName("");
      setDescription("");
      setCost("");
      setCategory("");
      setSizes("");
      setColors("");
      setMaterial("");
      setStock("");
      setFiles([]);
      setIsMultiple(false);
      setLoading(false);
      navigate("/fashion"); // Navigate to /fashion after successful upload
    } catch (error) {
      console.error("Error uploading files:", error);
      setError("Error uploading files");
      setLoading(false);
    }
  };

  return (
    <div className="sell-merch-container">
      <h2>Sell Your Merchandise</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Brand Name:</label>
          <input
            type="text"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Item Name:</label>
          <input
            type="text"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Cost:</label>
          <input
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Category:</label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Sizes:</label>
          <input
            type="text"
            value={sizes}
            onChange={(e) => setSizes(e.target.value)}
            placeholder="Comma-separated sizes"
            required
          />
        </div>
        <div className="form-group">
          <label>Colors:</label>
          <input
            type="text"
            value={colors}
            onChange={(e) => setColors(e.target.value)}
            placeholder="Comma-separated colors"
            required
          />
        </div>
        <div className="form-group">
          <label>Material:</label>
          <input
            type="text"
            value={material}
            onChange={(e) => setMaterial(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Stock:</label>
          <input
            type="number"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Upload Files:</label>
          <input
            type="file"
            multiple={isMultiple}
            onChange={handleFileChange}
            required
          />
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={isMultiple}
              onChange={() => setIsMultiple(!isMultiple)}
            />
            Multiple Products
          </label>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? (
            <>
              Uploading
              <BeatLoader color="#3498db" />{" "}
            </>
          ) : (
            "Submit"
          )}
        </button>
        {error && <div style={{ color: "red" }}>{error}</div>}
      </form>
    </div>
  );
};

export default SellMerch;
