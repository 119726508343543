import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <p>
        At Hxabyte Records, we are committed to protecting your privacy. This
        Privacy Policy explains how we collect, use, disclose, and safeguard
        your information when you visit our website [YourWebsiteURL.com],
        including any other media form, media channel, mobile website, or mobile
        application related or connected thereto (collectively, the "Site").
        Please read this privacy policy carefully. If you do not agree with the
        terms of this privacy policy, please do not access the site.
      </p>
      <h2>Information We Collect</h2>
      <p>
        We collect personal information that you voluntarily provide to us when
        registering at the Site, expressing an interest in obtaining information
        about us or our products and services, when participating in activities
        on the Site, or otherwise contacting us. The personal information that
        we collect depends on the context of your interactions with us and the
        Site, the choices you make, and the products and features you use. The
        personal information we collect may include the following:
      </p>
      <ul>
        <li>
          Personal Information Provided by You: We collect names; phone numbers;
          email addresses; usernames; passwords; contact preferences; contact or
          authentication data; billing addresses; debit/credit card numbers; and
          other similar information.
        </li>
      </ul>
      <h2>Use of Your Information</h2>
      <p>
        We use personal information collected via our Site for a variety of
        business purposes described below. We process your personal information
        for these purposes in reliance on our legitimate business interests, in
        order to enter into or perform a contract with you, with your consent,
        and/or for compliance with our legal obligations. We indicate the
        specific processing grounds we rely on next to each purpose listed
        below.
      </p>
      <ul>
        <li>To facilitate account creation and logon process.</li>
        <li>To send administrative information to you.</li>
        <li>To fulfill and manage your orders.</li>
        <li>To respond to inquiries and offer support.</li>
        <li>To request feedback.</li>
        <li>To send you marketing and promotional communications.</li>
        <li>To protect our Site.</li>
        <li>
          To enforce our terms, conditions, and policies for business purposes.
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
