import React, { useState } from "react";
import { useUser } from "./UserContext";
import "./css/RegisterModal.css";
import { ClipLoader } from "react-spinners";

function RegisterModal({ isRegisterOpen, onRegisterClose, toggleLoginModal }) {
  const { register, registerWithPhone } = useUser(); // Add registerWithPhone function
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneRegister, setIsPhoneRegister] = useState(false); // State to toggle between email and phone register
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP input visibility

  const handleEmailRegister = async (event) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await register(name, email, password, () => {
        onRegisterClose();
        setIsLoading(false);
      });
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handleSendOtp = async () => {
    setError("");
    setIsLoading(true);
    try {
      // Sending OTP logic for registration
      await registerWithPhone(phone); // Just send the OTP
      setOtpSent(true); // Show the OTP input after OTP is sent
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      // Verifying OTP logic and updating name
      await registerWithPhone(phone, otp, name, () => {
        onRegisterClose();
        setIsLoading(false);
      });
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  if (!isRegisterOpen) return null;

  return (
    <div className="register-modal-overlay" onClick={onRegisterClose}>
      <div
        className="register-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="register-modal-close" onClick={onRegisterClose}>
          &times;
        </span>
        <div className="dot-selector-container">
          <input
            type="radio"
            id="emailRegister"
            name="registerType"
            checked={!isPhoneRegister}
            onChange={() => setIsPhoneRegister(false)}
          />
          <label htmlFor="emailRegister">Email Register</label>

          <input
            type="radio"
            id="phoneRegister"
            name="registerType"
            checked={isPhoneRegister}
            onChange={() => setIsPhoneRegister(true)}
          />
          <label htmlFor="phoneRegister">Phone Register</label>
        </div>
        {isPhoneRegister ? (
          <form
            onSubmit={otpSent ? handleVerifyOtp : (e) => e.preventDefault()}
          >
            {error && <p className="register-error">{error}</p>}
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone Number"
              className="register-modal-input"
              disabled={otpSent}
            />
            {!otpSent ? (
              <button
                type="button"
                className="register-modal-button"
                onClick={handleSendOtp}
                disabled={isLoading || !phone}
              >
                {isLoading ? <ClipLoader size={10} color="#fff" /> : "Send OTP"}
              </button>
            ) : (
              <>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  className="register-modal-input"
                />
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="OTP"
                  className="register-modal-input"
                />
                <button
                  type="submit"
                  className="register-modal-button"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <ClipLoader size={10} color="#fff" />
                  ) : (
                    "Verify OTP"
                  )}
                </button>
              </>
            )}
          </form>
        ) : (
          <form onSubmit={handleEmailRegister}>
            {error && <p className="register-error">{error}</p>}
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              className="register-modal-input"
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="register-modal-input"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="register-modal-input"
            />
            <button
              type="submit"
              className="register-modal-button"
              disabled={isLoading}
            >
              {isLoading ? <ClipLoader size={10} color="#fff" /> : "Register"}
            </button>
          </form>
        )}
        <p className="register-modal-text">
          Already a user?{" "}
          <button
            className="register-modal-link"
            onClick={() => {
              onRegisterClose();
              toggleLoginModal();
            }}
          >
            Log in here
          </button>
          .
        </p>
      </div>
    </div>
  );
}

export default RegisterModal;
