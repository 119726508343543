import React, { createContext, useContext, useState, useEffect } from "react";
import { Databases, ID, Query } from "appwrite";
import client from "./appwriteConfig";
import { useUser } from "./UserContext";
import InstrumentAudioContext from "./InstrumentAudioContext";

const PatternInsSeqContext = createContext();

export const PatternInsSeqProvider = ({ children }) => {
  const { user } = useUser();
  const [beatPatterns, setBeatPatterns] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [pianoRollPatterns, setPianoRollPatterns] = useState([]);
  const databases = new Databases(client);

  useEffect(() => {
    if (user && user.email) {
      fetchBeatPatterns();
    }
  }, [user]);

  const fetchBeatPatterns = async () => {
    if (!user || !user.email) {
      console.warn("User is not logged in.");
      return;
    }

    try {
      const response = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID, // Your database ID
        process.env.REACT_APP_COLLECTION_BEAT_PATTERN_ID, // Your collection ID
        [Query.equal("userEmail", user.email)]
      );
      setBeatPatterns([...response.documents]);
    } catch (error) {
      console.error("Failed to retrieve user patterns:", error);
    }
  };

  const saveBeatPattern = async (
    sequences,
    pads,
    selectedPattern,
    beatPatterns,
    setBeatPatterns,
    fetchBeatPatterns,
    user,
    setIsSaving,
    selectedInstruments,
    instrumentVolumes // Include instrumentVolumes parameter
  ) => {
    if (!user || !user.email) {
      // Ensure user and user.email are checked
      alert("Please log in to save patterns.");
      return;
    }
    setIsSaving(true);
    try {
      const kitPatternData = sequences.reduce((acc, sequence, index) => {
        const filteredSequence = sequence.map((beat) => (beat ? 1 : 0));
        const hasOne = filteredSequence.includes(1);
        if (hasOne) {
          acc[pads[index].kitName || `pad-${index}`] = {
            sequence: filteredSequence,
            kitVolume:
              typeof InstrumentAudioContext.kitVolumes[pads[index].kitUrl] ===
              "number"
                ? InstrumentAudioContext.kitVolumes[pads[index].kitUrl]
                : 0.5,
          };
        }
        return acc;
      }, {});

      const instrumentPatternData = selectedInstruments.reduce(
        (acc, instrument) => {
          acc[instrument.InsName] = {
            InsName: instrument.InsName,
            PianoRollPatternNo: instrument.PianoRollPatternNo,
            InsVolume: instrument.InsVolume,
          };
          return acc;
        },
        {}
      );

      const kitsJson = JSON.stringify(kitPatternData);
      const instrumentsJson = JSON.stringify(instrumentPatternData);

      let newPatternId;
      if (selectedPattern === "initial") {
        const newDoc = await databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEAT_PATTERN_ID,
          ID.unique(),
          {
            userEmail: user.email,
            patternNumber: beatPatterns.length + 1,
            kitsJson: kitsJson, // Save kitsJson
            instrumentsJson: instrumentsJson, // Save instrumentsJson
          }
        );
        newPatternId = newDoc.$id;
      } else {
        await databases.updateDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEAT_PATTERN_ID,
          selectedPattern,
          {
            kitsJson: kitsJson, // Update kitsJson
            instrumentsJson: instrumentsJson, // Update instrumentsJson
          }
        );
        newPatternId = selectedPattern;
      }
      fetchBeatPatterns();
      return newPatternId;
    } catch (error) {
      console.error("Failed to save pattern:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const deleteBeatPattern = async (
    selectedPattern,
    beatPatterns,
    setBeatPatterns,
    setSelectedPattern,
    setSequences,
    pads,
    user,
    setIsDeleting
  ) => {
    if (!user || !user.email) {
      console.warn("User is not logged in.");
      return;
    }

    if (selectedPattern === "initial") {
      alert("Cannot delete the initial pattern.");
      return;
    }
    setIsDeleting(true);
    try {
      await databases.deleteDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_BEAT_PATTERN_ID,
        selectedPattern
      );
      alert("Pattern deleted successfully!");
      const updatedPatterns = beatPatterns.filter(
        (pattern) => pattern.$id !== selectedPattern
      );
      setBeatPatterns(updatedPatterns);
      setSelectedPattern("initial");
      setSequences(pads.map(() => Array(16).fill(false)));
    } catch (error) {
      console.error("Failed to delete pattern:", error);
    } finally {
      setIsDeleting(false); // Finish deleting
    }
  };

  const fetchPianoRollPatterns = async () => {
    try {
      const databases = new Databases(client);
      const response = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_PIANOROLL_PATTERN_ID
      );
      return response.documents;
    } catch (error) {
      console.error("Failed to fetch piano roll patterns:", error);
      return [];
    }
  };

  const savePianoRollPattern = async (
    patternNo,
    grid,
    notes,
    setSelectedPianoRollPattern
  ) => {
    try {
      const notesArray = [];
      grid.forEach((col, colIndex) => {
        col.forEach((active, rowIndex) => {
          if (active) {
            notesArray.push(`${notes[rowIndex]},${colIndex + 1}`);
          }
        });
      });

      const notesString = JSON.stringify(notesArray);
      let parsedPatternNo = parseInt(patternNo, 10);

      if (!parsedPatternNo || parsedPatternNo === 0) {
        const maxPatternNo = pianoRollPatterns.reduce(
          (max, pattern) => Math.max(max, pattern.patternNo),
          0
        );
        parsedPatternNo = maxPatternNo + 1;
      }

      const existingPattern = pianoRollPatterns.find(
        (pattern) => pattern.patternNo === parsedPatternNo
      );

      if (existingPattern) {
        await databases.updateDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_PIANOROLL_PATTERN_ID,
          existingPattern.$id,
          { Notes: notesString }
        );
      } else {
        const newPattern = await databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_PIANOROLL_PATTERN_ID,
          ID.unique(),
          {
            patternNo: parsedPatternNo,
            Notes: notesString,
            userEmail: user.email,
          }
        );
        setSelectedPianoRollPattern(parsedPatternNo); // Set the new pattern number
      }

      fetchPianoRollPatterns();
      // alert("Pattern saved successfully!");
    } catch (error) {
      console.error("Failed to save piano roll pattern:", error);
    }
  };

  const deletePianoRollPattern = async (patternNo) => {
    try {
      const parsedPatternNo = parseInt(patternNo, 10);
      const existingPattern = pianoRollPatterns.find(
        (pattern) => pattern.patternNo === parsedPatternNo
      );

      if (existingPattern) {
        await databases.deleteDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_PIANOROLL_PATTERN_ID,
          existingPattern.$id
        );

        fetchPianoRollPatterns();
      } else {
        console.log("Pattern not found!");
      }
    } catch (error) {
      console.error("Failed to delete piano roll pattern:", error);
    }
  };

  const fetchNotesPianoRoll = async (patternNo) => {
    const parsedPatternNo = parseInt(patternNo, 10);

    if (!parsedPatternNo || parsedPatternNo === 0) {
      console.warn("Invalid pattern number.");
      return [];
    }

    try {
      const response = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_PIANOROLL_PATTERN_ID,
        [Query.equal("patternNo", parsedPatternNo)]
      );
      if (response.documents.length > 0) {
        const notesString = response.documents[0].Notes;

        const notesArray = JSON.parse(notesString);
        return notesArray;
      } else {
        console.log("No notes found for pattern number:", patternNo);
        return [];
      }
    } catch (error) {
      console.error("Failed to fetch notes:", error);
      return [];
    }
  };

  return (
    <PatternInsSeqContext.Provider
      value={{
        fetchBeatPatterns,
        beatPatterns,
        setBeatPatterns,
        saveBeatPattern,
        deleteBeatPattern,
        pianoRollPatterns,
        setPianoRollPatterns,
        fetchPianoRollPatterns,
        savePianoRollPattern,
        deletePianoRollPattern,
        fetchNotesPianoRoll,
      }}
    >
      {children}
    </PatternInsSeqContext.Provider>
  );
};

export const usePatternInsSeq = () => {
  return useContext(PatternInsSeqContext);
};
