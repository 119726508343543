import React, { useState, useEffect, useRef } from "react";
import {
  FaPlay,
  FaPause,
  FaStop,
  FaSave,
  FaTrash,
  FaPlus,
} from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { usePatternInsSeq } from "./PatternInsSeqContext";
import { useUser } from "./UserContext";
import { Databases, ID, Query } from "appwrite";
import client from "./appwriteConfig";
import InstrumentAudioContext from "./InstrumentAudioContext";
import "./css/InstrumentPlayer.css"; // Import the CSS file

const databases = new Databases(client);

const generateNotes = () => {
  const notes = [];
  const noteNames = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  for (let octave = 0; octave < 5; octave++) {
    for (const note of noteNames) {
      notes.push(`${note}${octave}`);
    }
  }
  return notes;
};

const notes = generateNotes();

const InstrumentPlayer = ({ bpm, setBpm, pads }) => {
  const { user } = useUser();
  const {
    fetchBeatPatterns,
    beatPatterns = [],
    fetchNotesPianoRoll,
  } = usePatternInsSeq();
  const [selectedPatterns, setSelectedPatterns] = useState(Array(5).fill(""));
  const [currentPatternIndex, setCurrentPatternIndex] = useState(0);
  const [instruments, setInstruments] = useState({});
  const [kits, setKits] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [ledIndex, setLedIndex] = useState(0); // Adjusted to start from 1
  const [patternCache, setPatternCache] = useState({});
  const [isSavingSong, setIsSavingSong] = useState(false);
  const [songName, setSongName] = useState("");
  const [songNames, setSongNames] = useState([]);
  const [songVolume, setSongVolume] = useState(0.5); // Volume range is 0 to 1
  const intervalRef = useRef(null);
  const [isDeletingSong, setIsDeletingSong] = useState(false);
  const [dropdownWidth, setDropdownWidth] = useState(62); // Initial width for the pattern dropdown

  useEffect(() => {
    if (user) {
      fetchBeatPatterns();
      fetchSongNames();
    }
  }, [user]);

  const fetchSongNames = async () => {
    try {
      const response = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_SONG_PATTERN_ID,
        [Query.equal("email", user.email)]
      );
      setSongNames(response.documents.map((doc) => doc.songName));
    } catch (error) {
      console.error("Failed to fetch song names:", error);
    }
  };

  useEffect(() => {
    const loadAudioForPatterns = async () => {
      if (isPlaying) {
        stopPlaying();
      }
      unloadPatternAudio();
      for (const patternNumber of selectedPatterns) {
        if (patternNumber) {
          await loadPatternFromCache(patternNumber);
        }
      }
      if (isPlaying) {
        startPlaying();
      }
    };

    loadAudioForPatterns();
  }, [selectedPatterns, songName]);

  const handleSongVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setSongVolume(newVolume);
    InstrumentAudioContext.setSongVolume(newVolume); // Adjust global song volume
  };

  const loadPatternFromCache = async (patternNumber) => {
    if (patternCache[patternNumber]) {
      const { instruments, kits } = patternCache[patternNumber];
      setInstruments(instruments);
      setKits(kits);
    } else {
      const response = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_BEAT_PATTERN_ID,
        [
          Query.equal("patternNumber", parseInt(patternNumber, 10)),
          Query.equal("userEmail", user.email),
        ]
      );

      const document = response.documents[0];
      if (document) {
        const parsedInstruments = JSON.parse(document.instrumentsJson || "{}");
        const parsedKits = JSON.parse(document.kitsJson || "{}");

        for (const instrumentKey in parsedInstruments) {
          const instrument = parsedInstruments[instrumentKey];
          const notes = await fetchNotesPianoRoll(
            instrument.PianoRollPatternNo
          );
          instrument.notes = notes;
          await InstrumentAudioContext.loadAudio(instrument.InsName);
        }

        setPatternCache((prevCache) => ({
          ...prevCache,
          [patternNumber]: { instruments: parsedInstruments, kits: parsedKits },
        }));

        setInstruments(parsedInstruments);
        setKits(parsedKits);
      }
    }
  };

  const addPatternSelector = () => {
    setSelectedPatterns((prev) => [...prev, ""]);
  };
  const unloadPatternAudio = () => {
    setInstruments({});
    setKits({});
  };

  const handlePatternChange = async (index, event) => {
    const patternNumber = event.target.value;
    if (patternNumber === "delete") {
      setSelectedPatterns((prev) => {
        const newPatterns = [...prev];
        newPatterns.splice(index, 1);
        return newPatterns;
      });
    } else {
      setSelectedPatterns((prev) => {
        const newPatterns = [...prev];
        newPatterns[index] = patternNumber;
        return newPatterns;
      });
      if (patternNumber) {
        await loadPatternFromCache(patternNumber);
      }
    }
  };

  const saveSongPattern = async () => {
    setIsSavingSong(true);
    const songPattern = JSON.stringify({
      patterns: selectedPatterns,
      bpm,
    });
    try {
      const response = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_SONG_PATTERN_ID,
        [Query.equal("songName", songName), Query.equal("email", user.email)]
      );
      if (response.documents.length > 0) {
        // Update existing document
        const documentId = response.documents[0].$id;
        await databases.updateDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_SONG_PATTERN_ID,
          documentId,
          {
            songPattern,
          }
        );
      } else {
        // Create new document
        await databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_SONG_PATTERN_ID,
          ID.unique(),
          {
            songName,
            songPattern,
            email: user.email,
          }
        );
        fetchSongNames();
      }
    } catch (error) {
      console.error("Failed to save song pattern:", error);
    } finally {
      setIsSavingSong(false);
    }
  };

  const deleteSongPattern = async () => {
    setIsDeletingSong(true);
    try {
      const response = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_SONG_PATTERN_ID,
        [Query.equal("songName", songName), Query.equal("email", user.email)]
      );
      if (response.documents.length > 0) {
        const documentId = response.documents[0].$id;
        await databases.deleteDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_SONG_PATTERN_ID,
          documentId
        );
        setSongName("");
        setSelectedPatterns(Array(5).fill(""));
        fetchSongNames();
      }
    } catch (error) {
      console.error("Failed to delete song pattern:", error);
    } finally {
      setIsDeletingSong(false);
    }
  };

  const loadSongPattern = async (selectedSongName) => {
    try {
      const response = await databases.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_SONG_PATTERN_ID,
        [
          Query.equal("songName", selectedSongName),
          Query.equal("email", user.email),
        ]
      );
      const document = response.documents[0];
      if (document) {
        const { patterns, bpm } = JSON.parse(document.songPattern);
        setSelectedPatterns(patterns);
        setBpm(bpm);
        setSongName(selectedSongName); // Set the song name in the text input
      }
    } catch (error) {
      console.error("Failed to load song pattern:", error);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      startPlaying();
    } else {
      stopPlaying();
    }
    return () => stopPlaying();
  }, [isPlaying, bpm, instruments, kits, selectedPatterns]);

  const handlePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const handleStop = () => {
    setIsPlaying(false);
    setLedIndex(0); // Adjusted to start from 1
    stopPlaying();
    setCurrentPatternIndex(0);
  };

  const startPlaying = () => {
    const interval = (60 / bpm) * 250;
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(async () => {
      setLedIndex((prevIndex) => {
        const nextIndex = (prevIndex % 16) + 1; // Adjusted to start from 1

        for (const instrumentKey in instruments) {
          const instrument = instruments[instrumentKey];
          const currentNotes = instrument.notes.filter((note) => {
            const [, step] = note.split(",");
            return parseInt(step, 10) === nextIndex;
          });

          if (currentNotes.length > 0) {
            currentNotes.forEach((note) => {
              const [noteName] = note.split(",");
              const noteIndex = notes.findIndex((n) => n === noteName.trim());
              if (noteIndex !== -1) {
                InstrumentAudioContext.playPianoRollNote(
                  noteIndex,
                  notes,
                  instrument.InsName
                );
              }
            });
          }
        }

        for (const pad of pads) {
          const kit = kits[pad.kitName];
          if (kit && kit.sequence[nextIndex - 1]) {
            // Adjusted for 0-based array index
            InstrumentAudioContext.playSound(pad.kitUrl, kit.kitVolume);
          }
        }

        if (nextIndex === 16) {
          unloadPatternAudio();

          setCurrentPatternIndex((prev) => {
            const nextPatternIndex = (prev + 1) % selectedPatterns.length;
            if (selectedPatterns[nextPatternIndex]) {
              loadPatternFromCache(selectedPatterns[nextPatternIndex]);
            }
            return nextPatternIndex;
          });
        }

        return nextIndex;
      });
    }, interval);
  };

  const stopPlaying = () => {
    clearInterval(intervalRef.current);
    InstrumentAudioContext.stopAllNotes();
  };

  const renderLEDIndicators = () => {
    const leds = [];
    for (let i = 1; i <= 16; i++) {
      leds.push(
        <div key={i} className={`led1 ${i === ledIndex ? "active" : ""}`}></div>
      );
    }
    return leds;
  };

  return (
    <div className={`instrument-player ${isPlaying ? "playing" : ""}`}>
      <div className="instrument-player-controls">
        <div className="song-head1">
          {" "}
          <h3>SONG</h3>
          <button onClick={handlePlayPause} className="play-button-insplayer">
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button onClick={handleStop} className="play-button-insplayer">
            <FaStop />
          </button>
          <div>
            <label htmlFor="song-volume"></label>
            <input
              type="range"
              id="song-volume"
              min="0"
              max="1"
              step="0.01"
              className="volume-slider"
              value={songVolume}
              onChange={handleSongVolumeChange}
            />
          </div>{" "}
        </div>
        <div className="song-head1">
          {" "}
          <select
            onChange={(e) => loadSongPattern(e.target.value)}
            value={songName}
            className="song-pattern-dropdown "
          >
            <option value="" disabled className="song-pattern-option ">
              Select Song
            </option>
            {songNames.map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </select>{" "}
          <input
            type="text"
            placeholder="Enter song name"
            value={songName}
            onChange={(e) => setSongName(e.target.value)}
          />{" "}
          <button
            onClick={saveSongPattern}
            className="save-pattern-button"
            disabled={isSavingSong || !songName}
          >
            {isSavingSong ? <ClipLoader size={20} color="#fff" /> : <FaSave />}
          </button>
          <button
            onClick={deleteSongPattern}
            className="delete-pattern-button"
            disabled={!songName}
          >
            {isDeletingSong ? (
              <ClipLoader size={20} color="#fff" />
            ) : (
              <FaTrash />
            )}
          </button>
        </div>
      </div>{" "}
      <div className="p-sel-container">
        <div className="pattern-selectors">
          {selectedPatterns.map((pattern, index) => (
            <select
              key={index}
              value={pattern}
              onChange={(event) => handlePatternChange(index, event)}
              className={`pattern-dropdown1 ${
                currentPatternIndex === index ? "active-pattern" : ""
              }`}
              style={{ width: `${dropdownWidth}px` }} // Dynamic width
            >
              <option value=""> {index + 1}</option>
              {beatPatterns.map((pattern) => (
                <option
                  key={pattern.patternNumber}
                  value={pattern.patternNumber}
                >
                  p{pattern.patternNumber}
                </option>
              ))}{" "}
              <option value="delete">Delete</option>
            </select>
          ))}{" "}
        </div>{" "}
        <button onClick={addPatternSelector} className="add-instrument-button2">
          <FaPlus />
        </button>{" "}
      </div>
      <div className="led-n-slider">
        {" "}
        <div className="led-container">{renderLEDIndicators()}</div>
        <div className="slider-container">
          <input
            type="range"
            min="20"
            max="92"
            value={dropdownWidth}
            onChange={(e) => setDropdownWidth(e.target.value)}
            className="dropdown-width-slider"
          />
        </div>
      </div>
    </div>
  );
};

export default InstrumentPlayer;
