import React from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import "./css/PaymentFail.css"; // Create a corresponding CSS file for styling

const PaymentFail = () => {
  const navigate = useNavigate();
  const { cart } = useUser();

  const handleRetry = () => {
    navigate("/checkout", { state: { cart } });
  };

  return (
    <div className="payment-fail-container">
      <h2>Payment Failed</h2>
      <p>We're sorry, but your payment has failed.</p>
      <button className="retry-button" onClick={handleRetry}>
        Try Again
      </button>
    </div>
  );
};

export default PaymentFail;
