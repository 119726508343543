import React, { useState, useEffect } from "react";
import { Account } from "appwrite";
import client from "./appwriteConfig";
import { useUser } from "./UserContext";
import "./css/ProfileSettings.css"; // Create this CSS file for styling
import ClipLoader from "react-spinners/ClipLoader";

const countryCodes = [
  { code: "+1", country: "US" },
  { code: "+91", country: "India" },
  { code: "+44", country: "UK" },
  { code: "+61", country: "Australia" },
  { code: "+81", country: "Japan" },
  { code: "+49", country: "Germany" },
  { code: "+33", country: "France" },
];

const ProfileSettings = () => {
  const { user, setUser } = useUser();
  const [username, setUsername] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+91"); // Default country code to India
  const [message, setMessage] = useState("");

  const [isUpdatingUsername, setIsUpdatingUsername] = useState(false);
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isSettingPhone, setIsSettingPhone] = useState(false);

  const account = new Account(client);

  useEffect(() => {
    setUsername(user.name);
    setEmail(user.email);
    splitPhoneNumber(user.phone || "");
  }, [user]);

  const splitPhoneNumber = (fullPhoneNumber) => {
    for (const { code } of countryCodes) {
      if (fullPhoneNumber.startsWith(code)) {
        setCountryCode(code);
        setPhone(fullPhoneNumber.slice(code.length));
        return;
      }
    }
    setPhone(fullPhoneNumber); // Default case if no code matches
  };

  const handleUpdateUsername = async () => {
    setIsUpdatingUsername(true);
    try {
      await account.updateName(username);
      setUser({ ...user, name: username }); // Update the user context with the new username
      setMessage("Username updated successfully.");
    } catch (error) {
      setMessage("Failed to update username: " + error.message);
    } finally {
      setIsUpdatingUsername(false);
    }
  };

  const handleChangeEmail = async () => {
    setIsChangingEmail(true);
    try {
      await account.updateEmail(email, password);
      setUser({ ...user, email });
      setMessage("Email updated successfully.");
    } catch (error) {
      setMessage("Failed to update email: " + error.message);
    } finally {
      setIsChangingEmail(false);
    }
  };

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }
    setIsChangingPassword(true);
    try {
      await account.updatePassword(password);
      setMessage("Password updated successfully.");
    } catch (error) {
      setMessage("Failed to update password: " + error.message);
    } finally {
      setIsChangingPassword(false);
    }
  };

  const handleSetPhone = async () => {
    setIsSettingPhone(true);
    const fullPhoneNumber = `${countryCode}${phone}`;
    try {
      await account.updatePhone(fullPhoneNumber, password);
      setUser({ ...user, phone: fullPhoneNumber }); // Update the user context with the new phone number
      setMessage("Phone number updated successfully.");
    } catch (error) {
      setMessage("Failed to update phone number: " + error.message);
    } finally {
      setIsSettingPhone(false);
    }
  };

  const renderCountryCodeOptions = () => {
    return (
      <div>
        <select
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
          className="form-country"
        >
          {countryCodes.map(({ code, country }) => (
            <option key={code} value={code}>
              {code} ({country})
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div className="profile-settings-container">
      <h2>Profile Settings</h2>
      {message && <p className="message">{message}</p>}
      <div className="profile-settings-form">
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button onClick={handleUpdateUsername} disabled={isUpdatingUsername}>
            {isUpdatingUsername ? (
              <ClipLoader size={20} color="#fff" />
            ) : (
              "Update Username"
            )}
          </button>
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handleChangeEmail} disabled={isChangingEmail}>
            {isChangingEmail ? (
              <ClipLoader size={20} color="#fff" />
            ) : (
              "Change Email"
            )}
          </button>
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button onClick={handleChangePassword} disabled={isChangingPassword}>
            {isChangingPassword ? (
              <ClipLoader size={20} color="#fff" />
            ) : (
              "Change Password"
            )}
          </button>
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <div className="phone-input-group">
            {renderCountryCodeOptions()}
            <input
              type="number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <button onClick={handleSetPhone} disabled={isSettingPhone}>
            {isSettingPhone ? (
              <ClipLoader size={20} color="#fff" />
            ) : (
              "Set Phone Number"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
