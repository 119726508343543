// src/appwriteConfig.js
import { Account, Client } from "appwrite";

const client = new Client();

client
  .setEndpoint("https://cloud.appwrite.io/v1") // Set only once
  .setProject(process.env.REACT_APP_PROJECT_ID); // Replace with your project ID
export const account = new Account(client);
export default client;
