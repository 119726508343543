import React, { useState } from "react";
import { Chess } from "chess.js";

const PgnUploader = () => {
  const [isFileProcessed, setIsFileProcessed] = useState(false); // State to track if the file has been processed

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && !isFileProcessed) {
      // Only process if a file is selected and hasn't been processed
      const reader = new FileReader();

      reader.onload = (e) => {
        const pgn = e.target.result;
        console.log("Uploaded PGN Content:\n", pgn); // Log the raw PGN content to check for formatting issues
        // Call function to parse and display PGN
        setIsFileProcessed(true); // Set state to true to prevent further processing
      };

      reader.readAsText(file); // Read the uploaded file as text
    }
  };

  return (
    <div className="pgn-uploader">
      <h2>Upload PGN File</h2>
      <input
        type="file"
        accept=".pgn"
        onChange={handleFileUpload}
        // Disable input after file is processed
      />
      {isFileProcessed && (
        <p>PGN file processed. Refresh to upload another file.</p>
      )}
    </div>
  );
};

export default PgnUploader;
