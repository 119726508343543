import React, { useEffect, useRef, useState } from "react";
import { useUser } from "./UserContext";
import WaveSurfer from "wavesurfer.js";
import {
  BsPlayFill,
  BsPauseFill,
  BsArrowDown,
  BsStopFill,
} from "react-icons/bs";
import "./css/MiniPlayer.css";

function MiniPlayer({ currentTrack, onTogglePlay, playing }) {
  const { storage, stopCurrentTrack } = useUser();
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [volume, setVolume] = useState(0.5); // Initial volume set to 0.5

  useEffect(() => {
    if (playing) {
      setIsVisible(true); // Show the MiniPlayer when playing
    }
  }, [playing]);

  const handleHidePlayer = () => {
    setIsVisible(false); // Hide the MiniPlayer
    stopCurrentTrack(); // Stop the music
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    if (wavesurfer.current) {
      wavesurfer.current.setVolume(newVolume);
    }
  };

  const handleStop = () => {
    if (wavesurfer.current) {
      wavesurfer.current.stop();
      if (playing) {
        onTogglePlay(); // Toggle the play/pause button back
      }
    }
  };

  useEffect(() => {
    if (wavesurfer.current) {
      wavesurfer.current.destroy();
    }

    if (currentTrack) {
      const fileId = currentTrack.audioUrl; // Assuming audioUrl is the fileId in storage
      const fileUrl = storage.getFileView(
        process.env.REACT_APP_BUCKET_BEATS_ID,
        fileId
      );
      setAudioUrl(fileUrl);

      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "purple",
        progressColor: "magenta",
        backend: "MediaElement",
        mediaControls: false,
        height: 30,
      });

      wavesurfer.current.load(fileUrl);
      wavesurfer.current.setVolume(volume); // Use the current volume setting

      wavesurfer.current.on("ready", () => {
        if (playing) {
          wavesurfer.current.play();
        }
      });
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, [currentTrack]);

  useEffect(() => {
    if (wavesurfer.current) {
      wavesurfer.current.setVolume(volume);
    }
  }, [volume]);

  useEffect(() => {
    if (wavesurfer.current) {
      if (playing) {
        wavesurfer.current.play();
      } else {
        wavesurfer.current.pause();
      }
    }
  }, [playing]);

  return (
    <div className={`mini-player-container ${isVisible ? "visible" : ""}`}>
      <div className="waveform" ref={waveformRef}></div>
      <div className="mini-player-row">
        <div className="left-section">
          <img
            src={
              currentTrack
                ? storage.getFileView(
                    process.env.REACT_APP_BUCKET_BEATS_ID,
                    currentTrack.imageUrl
                  )
                : "default-image-url"
            } // Provide a default image URL or handle the case differently
            alt="Album Cover"
            className="album-cover"
          />
          <div className="track-info">
            <div>{currentTrack ? currentTrack.artistName : "No Artist"}</div>
            <div>{currentTrack ? currentTrack.beatName : "No Track"}</div>
          </div>
        </div>
        <div className="center-section">
          <button onClick={onTogglePlay} className="play-pause-btn">
            {playing ? <BsPauseFill /> : <BsPlayFill />}
          </button>
          <button onClick={handleStop} className="stop-btn">
            <BsStopFill />
          </button>
        </div>
        <div className="right-section">
          <input
            type="range"
            className="volume-slider"
            min="0"
            max="1"
            step="0.01"
            value={volume} // Bind to the state
            onChange={handleVolumeChange}
          />
          <button onClick={handleHidePlayer} className="control-btn">
            <BsArrowDown /> {/* Display the down arrow icon */}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MiniPlayer;
