import React from "react";
import "./css/Services.css"; // Import the CSS file for styling

function Services() {
  return (
    <div className="services-container">
      <h2>Services:</h2>
      <div className="service-topic">
        <div className="service-item">
          <h3>Mixing</h3>
          <p>
            Professional mixing services to bring clarity, balance, and depth to
            your audio tracks, ensuring every element blends seamlessly for a
            polished sound.
          </p>
        </div>
        <div className="service-item">
          <h3>Mastering</h3>
          <p>
            Elevate your tracks to industry standards with expert mastering,
            enhancing overall clarity, dynamics, and consistency for optimal
            playback across various platforms.
          </p>
        </div>
        <div className="service-item">
          <h3>Custom Beat/Song Production</h3>
          <p>
            Tailored beat and song production services to bring your musical
            vision to life, from crafting unique instrumentals to arranging and
            recording custom compositions.
          </p>
        </div>
        <div className="service-item">
          <h3>Vocals Recording / Dubbing</h3>
          <p>
            Capture your vocals with precision and clarity, whether recording
            new tracks or dubbing vocals for existing projects, ensuring
            professional-quality audio production.
          </p>
        </div>
        <div className="service-item">
          <h3>Jingles For Ads/Commercials</h3>
          <p>
            Create catchy and memorable jingles tailored to your brand or
            advertisement, designed to captivate audiences and leave a lasting
            impression.
          </p>
        </div>
        <div className="service-item">
          <h3>Soundtracks For Movies/ShortFilms</h3>
          <p>
            Compose original soundtracks and scores to complement the narrative
            and enhance the emotional impact of movies, short films, and visual
            storytelling projects.
          </p>
        </div>
        <div className="service-item">
          <h3>Soundtracks For TV Shows</h3>
          <p>
            Produce captivating soundtracks and theme music for television
            shows, capturing the essence of the program and engaging viewers
            from the opening credits.
          </p>
        </div>
        <div className="service-item">
          <h3>Book for an Event/Performance</h3>
          <p>
            Elevate your event or performance with live music tailored to your
            audience and venue, creating memorable experiences through dynamic
            and engaging performances.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
