import React, { useState } from "react";
import "./css/ContactUs.css"; // Import the CSS file

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send email with description to hxabytetech@gmail.com
    const emailContent = {
      subject: "Contact Form Submission",
      body: `Name: ${name}\nEmail: ${email}\nDescription: ${description}`,
    };
    // You can implement sending email functionality here
    console.log("Email sent:", emailContent);
    // Reset fields
    setName("");
    setEmail("");
    setDescription("");
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p className="contact-description">
        For inquiries or assistance, please fill out the form below or contact
        us directly using the phone number provided.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={handleNameChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            name="description"
            value={description}
            onChange={handleDescriptionChange}
            required
            className="form-control"
          />
        </div>
        <button type="submit" className="btn-submit">
          Submit
        </button>
      </form>
      <div className="contact-info">
        <h2>Contact Information</h2>
        <p>
          <strong>Phone:</strong> 08248306704
        </p>
        <p>
          <strong>Email:</strong> hxabytetech@gmail.com
        </p>
        <p>
          <strong>ADDRESS:</strong> Hxabyte Records, 172/7 , Annai Thresa Nagar,
          Convent Road, New Fairlands, Salem 636016, Tamil Nadu, INDIA
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
