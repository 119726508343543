import React from "react";
import "./css/GameOverModal.css";

const GameOverModal = ({ winner, onClose }) => {
  return (
    <div className="gameover-modal">
      <div className="gameover-modal-content">
        <h2>{winner} wins by checkmate!</h2>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default GameOverModal;
