import React, { useEffect, useState } from "react";
import { Databases, Query, Storage, ID } from "appwrite";
import client from "./appwriteConfig";
import { useUser } from "./UserContext";
import { BeatLoader, ClipLoader } from "react-spinners";
import "./css/YourProducts.css";

const databases = new Databases(client);
const storage = new Storage(client);

const YourProducts = () => {
  const { user } = useUser();
  const [beats, setBeats] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const [fashions, setFashions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(null);
  const [editing, setEditing] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [newImage, setNewImage] = useState(null); // State for new image file

  useEffect(() => {
    const fetchProductsAndTutorials = async () => {
      if (!user) return;

      try {
        const beatsResponse = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEATS_ID,
          [Query.equal("email", user.email)]
        );

        const tutorialsResponse = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_TUTORIALS_ID,
          [Query.equal("email", user.email)]
        );

        const fashionsResponse = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_FASHION_ID,
          [Query.equal("email", user.email)]
        );

        // Fetch image URLs from Appwrite Storage for each item
        const fetchImageUrls = async (items, field, bucketId) => {
          return await Promise.all(
            items.map(async (item) => {
              const fileView = await storage.getFileView(bucketId, item[field]);
              return { ...item, imageUrl: fileView.href };
            })
          );
        };

        const beatsWithImages = await fetchImageUrls(
          beatsResponse.documents,
          "imageUrl",
          process.env.REACT_APP_BUCKET_BEATS_ID
        );
        const tutorialsWithImages = await fetchImageUrls(
          tutorialsResponse.documents,
          "thumbnailUrl",
          process.env.REACT_APP_BUCKET_TUTORIALS_ID
        );
        const fashionsWithImages = await fetchImageUrls(
          fashionsResponse.documents,
          "fashionUrl",
          process.env.REACT_APP_BUCKET_FASHION_ID
        );

        setBeats(beatsWithImages);
        setTutorials(tutorialsWithImages);
        setFashions(fashionsWithImages);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch products and tutorials", error);
        setLoading(false);
      }
    };

    fetchProductsAndTutorials();
  }, [user]);

  const handleDelete = async (collectionId, documentId, fileId, bucketId) => {
    setDeleting(documentId);
    try {
      await databases.deleteDocument(
        process.env.REACT_APP_DATABASE_ID,
        collectionId,
        documentId
      );
      await storage.deleteFile(bucketId, fileId);
      setBeats(beats.filter((beat) => beat.$id !== documentId));
      setTutorials(tutorials.filter((tutorial) => tutorial.$id !== documentId));
      setFashions(fashions.filter((fashion) => fashion.$id !== documentId));
    } catch (error) {
      console.error("Failed to delete item", error);
    } finally {
      setDeleting(null);
    }
  };

  const handleEdit = (item, type) => {
    setEditing(item.$id);
    setEditValues({
      name:
        type === "beat"
          ? item.beatName
          : type === "tutorial"
          ? item.tutorialTitle
          : item.itemName,
      cost: item.cost,
      type,
    });
    setNewImage(null); // Reset new image file when editing starts
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "cost" && value && !/^\d+$/.test(value)) {
      return; // Only allow integer values for cost
    }
    setEditValues({ ...editValues, [name]: value });
  };

  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]); // Set the new image file
  };

  const handleSave = async (item) => {
    const { type, name, cost } = editValues;
    const updateData =
      type === "beat"
        ? { beatName: name, cost: parseInt(cost, 10) }
        : type === "tutorial"
        ? { tutorialTitle: name, cost: parseInt(cost, 10) }
        : { itemName: name, cost: parseInt(cost, 10) };
    const collectionId =
      type === "beat"
        ? process.env.REACT_APP_COLLECTION_BEATS_ID
        : type === "tutorial"
        ? process.env.REACT_APP_COLLECTION_TUTORIALS_ID
        : process.env.REACT_APP_COLLECTION_FASHION_ID;

    try {
      // Upload new image if selected
      if (newImage) {
        const bucketId =
          type === "beat"
            ? process.env.REACT_APP_BUCKET_BEATS_ID
            : type === "tutorial"
            ? process.env.REACT_APP_BUCKET_TUTORIALS_ID
            : process.env.REACT_APP_BUCKET_FASHION_ID;

        const fileId = ID.unique();
        const uploadedFile = await storage.createFile(
          bucketId,
          fileId,
          newImage
        );
        updateData[
          type === "beat"
            ? "imageUrl"
            : type === "tutorial"
            ? "thumbnailUrl"
            : "fashionUrl"
        ] = uploadedFile.$id; // Update the document with new file ID
      }

      await databases.updateDocument(
        process.env.REACT_APP_DATABASE_ID,
        collectionId,
        item.$id,
        updateData
      );

      // Update state after saving
      if (type === "beat") {
        setBeats(
          beats.map((beat) =>
            beat.$id === item.$id
              ? { ...beat, beatName: name, cost: parseInt(cost, 10) }
              : beat
          )
        );
      } else if (type === "tutorial") {
        setTutorials(
          tutorials.map((tutorial) =>
            tutorial.$id === item.$id
              ? { ...tutorial, tutorialTitle: name, cost: parseInt(cost, 10) }
              : tutorial
          )
        );
      } else {
        setFashions(
          fashions.map((fashion) =>
            fashion.$id === item.$id
              ? { ...fashion, itemName: name, cost: parseInt(cost, 10) }
              : fashion
          )
        );
      }

      setEditing(null);
      setNewImage(null); // Clear the image file after saving
    } catch (error) {
      console.error("Failed to update item", error);
    }
  };

  return (
    <div className="your-products-container">
      <h2>Your Products</h2>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <BeatLoader color="#3498db" />
        </div>
      ) : (
        <>
          {/* Display Beats */}
          <h3>Beats</h3>
          <div className="products-grid">
            {beats.map((beat) => (
              <div key={beat.$id} className="product-item">
                <img
                  src={beat.imageUrl}
                  alt={beat.beatName}
                  className="product-image"
                />
                {editing === beat.$id && editValues.type === "beat" ? (
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={editValues.name}
                      onChange={handleInputChange}
                    />
                    <input
                      type="number"
                      name="cost"
                      value={editValues.cost}
                      onChange={handleInputChange}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <button
                      className="save-button"
                      onClick={() => handleSave(beat)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div>
                    {beat.beatName} - {beat.cost}
                  </div>
                )}
                <div>
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(beat, "beat")}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-button"
                    onClick={() =>
                      handleDelete(
                        process.env.REACT_APP_COLLECTION_BEATS_ID,
                        beat.$id,
                        beat.imageUrl,
                        process.env.REACT_APP_BUCKET_BEATS_ID
                      )
                    }
                    disabled={deleting === beat.$id}
                  >
                    {deleting === beat.$id ? (
                      <ClipLoader size={12} />
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Display Tutorials */}
          <h3>Tutorials</h3>
          <div className="products-grid">
            {tutorials.map((tutorial) => (
              <div key={tutorial.$id} className="product-item">
                <img
                  src={tutorial.imageUrl}
                  alt={tutorial.tutorialTitle}
                  className="product-image"
                />
                {editing === tutorial.$id && editValues.type === "tutorial" ? (
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={editValues.name}
                      onChange={handleInputChange}
                    />
                    <input
                      type="number"
                      name="cost"
                      value={editValues.cost}
                      onChange={handleInputChange}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <button
                      className="save-button"
                      onClick={() => handleSave(tutorial)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div>
                    {tutorial.tutorialTitle} - {tutorial.cost}
                  </div>
                )}
                <div>
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(tutorial, "tutorial")}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-button"
                    onClick={() =>
                      handleDelete(
                        process.env.REACT_APP_COLLECTION_TUTORIALS_ID,
                        tutorial.$id,
                        tutorial.imageUrl,
                        process.env.REACT_APP_BUCKET_TUTORIALS_ID
                      )
                    }
                    disabled={deleting === tutorial.$id}
                  >
                    {deleting === tutorial.$id ? (
                      <ClipLoader size={12} />
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Display Fashion Items */}
          <h3>Fashion Items</h3>
          <div className="products-grid">
            {fashions.map((fashion) => (
              <div key={fashion.$id} className="product-item">
                <img
                  src={fashion.imageUrl}
                  alt={fashion.itemName}
                  className="product-image"
                />
                {editing === fashion.$id && editValues.type === "fashion" ? (
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={editValues.name}
                      onChange={handleInputChange}
                    />
                    <input
                      type="number"
                      name="cost"
                      value={editValues.cost}
                      onChange={handleInputChange}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <button
                      className="save-button"
                      onClick={() => handleSave(fashion)}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div>
                    {fashion.itemName} - {fashion.cost}
                  </div>
                )}
                <div>
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(fashion, "fashion")}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-button"
                    onClick={() =>
                      handleDelete(
                        process.env.REACT_APP_COLLECTION_FASHION_ID,
                        fashion.$id,
                        fashion.imageUrl,
                        process.env.REACT_APP_BUCKET_FASHION_ID
                      )
                    }
                    disabled={deleting === fashion.$id}
                  >
                    {deleting === fashion.$id ? (
                      <ClipLoader size={12} />
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default YourProducts;
