import React, { useEffect, useState } from "react";
import { Databases, Query, Storage } from "appwrite";
import { useUser } from "./UserContext";
import client from "./appwriteConfig";
import { BeatLoader } from "react-spinners";
import "./css/Purchased.css";

const Purchased = () => {
  const { user } = useUser();
  const [purchasedItems, setPurchasedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPurchasedItems = async () => {
      if (!user) return;

      const databases = new Databases(client);
      const storage = new Storage(client);

      try {
        const response = await databases.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_TRANSACTIONS,
          [
            Query.equal("email", user.email),
            Query.equal("paymentStatus", "PAYMENT_SUCCESS"),
          ]
        );

        const items = await Promise.all(
          response.documents.map(async (item) => {
            let cartItems = [];
            try {
              cartItems = JSON.parse(item.cart);
            } catch (error) {
              console.error("Failed to parse cart items:", error);
            }

            const updatedCartItems = await Promise.all(
              cartItems.map(async (product) => {
                if (product.audioUrls) {
                  product.audioUrlLink = await getFileViewUrl(
                    storage,
                    process.env.REACT_APP_BUCKET_BEATS_ID,
                    product.audioUrls
                  );

                  if (product.imageUrls) {
                    product.albumArtLink = await getFileViewUrl(
                      storage,
                      process.env.REACT_APP_BUCKET_BEATS_ID,
                      product.imageUrls
                    );
                  }
                }

                if (product.videoUrls) {
                  product.videoUrlLink = await getFileViewUrl(
                    storage,
                    process.env.REACT_APP_BUCKET_TUTORIALS_ID,
                    product.videoUrls
                  );
                }

                if (product.fashionUrls) {
                  product.fashionUrlLink = await getFileViewUrl(
                    storage,
                    process.env.REACT_APP_BUCKET_FASHION_ID,
                    product.fashionUrls
                  );
                }

                return product;
              })
            );

            return { ...item, cart: updatedCartItems };
          })
        );

        setPurchasedItems(items);
      } catch (error) {
        console.error("Failed to fetch purchased items:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPurchasedItems();
  }, [user]);

  const getFileViewUrl = async (storage, bucketId, fileId) => {
    if (!fileId) {
      console.error("Missing fileId");
      return null;
    }

    try {
      const response = await storage.getFileView(bucketId, fileId);
      return response.href;
    } catch (error) {
      console.error("Failed to get file view URL:", error);
      return null;
    }
  };

  return (
    <div className="purchased-container">
      <h2>Purchased Items</h2>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <BeatLoader color="#3498db" />
        </div>
      ) : (
        <>
          {purchasedItems.length === 0 ? (
            <div>No purchased items found.</div>
          ) : (
            <div className="purchased-list">
              {purchasedItems.map((item) => (
                <div className="purchased-item" key={item.$id}>
                  <h3>Cart ID: {item.$id}</h3>
                  <p>Total Price: {item.totalPrice}</p>
                  <p>
                    Purchase Date:{" "}
                    {new Date(item.$createdAt).toLocaleDateString()}
                  </p>
                  <div className="cart-items">
                    {item.cart.map((product, index) => (
                      <div className="cart-item" key={index}>
                        <h4>{product.productName}</h4>
                        <p>Cost: {product.cost}</p>
                        <p>Quantity: {product.quantity}</p>

                        {product.audioUrlLink && (
                          <div className="purchased-audio-section">
                            {product.albumArtLink && (
                              <img
                                src={product.albumArtLink}
                                alt="Album Art"
                                className="purchased-album-art-image"
                              />
                            )}
                            <audio controls className="purchased-audio-player">
                              <source
                                src={product.audioUrlLink}
                                type="audio/mp3"
                              />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        )}

                        {product.videoUrlLink && (
                          <video controls className="purchased-video-player">
                            <source
                              src={product.videoUrlLink}
                              type="video/mp4"
                            />
                            Your browser does not support the video element.
                          </video>
                        )}

                        {product.fashionUrlLink && (
                          <img
                            src={product.fashionUrlLink}
                            alt="Fashion Item"
                            className="purchased-product-image"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Purchased;
