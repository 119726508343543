// Import sound files

// Function to play sounds
export const playSound = (sound) => {
  const audio = new Audio(sound);
  audio.play();
};

// Check if a move puts the king in check
export const isKingInCheck = (board, color) => {
  const kingPosition = findKing(board, color);
  if (!kingPosition) return false;

  const [kingRow, kingCol] = kingPosition;
  for (let row = 0; row < 8; row++) {
    for (let col = 0; col < 8; col++) {
      const piece = board[row][col];
      if (
        piece &&
        ((color === "white" && piece === piece.toLowerCase()) ||
          (color === "black" && piece === piece.toUpperCase()))
      ) {
        const moves = generateLegalMoves(row, col, piece, board);
        if (moves.some(([r, c]) => r === kingRow && c === kingCol)) {
          return true;
        }
      }
    }
  }
  return false;
};

// Check if the current state is checkmate
export const isCheckmate = (board, color) => {
  for (let row = 0; row < 8; row++) {
    for (let col = 0; col < 8; col++) {
      const piece = board[row][col];
      if (
        piece &&
        ((color === "white" && piece === piece.toUpperCase()) ||
          (color === "black" && piece === piece.toLowerCase()))
      ) {
        const moves = generateLegalMoves(row, col, piece, board);
        const validMoves = filterOutMovesThatCauseCheck(
          row,
          col,
          moves,
          board,
          piece
        );
        if (validMoves.length > 0) return false; // There is at least one legal move
      }
    }
  }
  return true; // No legal moves left
};

// Function to generate all possible moves for a given color
export const getAllPossibleMoves = (color, board) => {
  const moves = [];
  for (let row = 0; row < 8; row++) {
    for (let col = 0; col < 8; col++) {
      const piece = board[row][col];
      if (
        (color === "white" && piece === piece.toUpperCase()) ||
        (color === "black" && piece === piece.toLowerCase())
      ) {
        const pieceMoves = generateLegalMoves(row, col, piece, board);
        const validMoves = filterOutMovesThatCauseCheck(
          row,
          col,
          pieceMoves,
          board,
          piece
        );
        validMoves.forEach((move) =>
          moves.push({ start: [row, col], end: move })
        );
      }
    }
  }
  return moves;
};

// Function to get possible castling moves
export const getCastlingMoves = (
  row,
  col,
  isWhiteTurn,
  board,
  castlingRights
) => {
  const castlingMoves = [];
  // Kingside castling
  if (
    isWhiteTurn &&
    castlingRights.whiteKingSide &&
    board[row][col + 1] === "" && // Empty square next to king
    board[row][col + 2] === "" && // Empty square for king to move to
    !isKingInCheck(board, "white") && // King is not currently in check
    !isKingInCheck(moveKing(board, row, col + 1, isWhiteTurn), "white") && // Path is not in check
    !isKingInCheck(moveKing(board, row, col + 2, isWhiteTurn), "white")
  ) {
    castlingMoves.push([row, col + 2]);
  }

  // Queenside castling
  if (
    isWhiteTurn &&
    castlingRights.whiteQueenSide &&
    board[row][col - 1] === "" && // Empty square next to king
    board[row][col - 2] === "" && // Empty square for king to move to
    board[row][col - 3] === "" && // Empty square next to rook
    !isKingInCheck(board, "white") && // King is not currently in check
    !isKingInCheck(moveKing(board, row, col - 1, isWhiteTurn), "white") && // Path is not in check
    !isKingInCheck(moveKing(board, row, col - 2, isWhiteTurn), "white")
  ) {
    castlingMoves.push([row, col - 2]);
  }

  // Kingside castling for Black
  if (
    !isWhiteTurn &&
    castlingRights.blackKingSide &&
    board[row][col + 1] === "" && // Empty square next to king
    board[row][col + 2] === "" && // Empty square for king to move to
    !isKingInCheck(board, "black") && // King is not currently in check
    !isKingInCheck(moveKing(board, row, col + 1, isWhiteTurn), "black") && // Path is not in check
    !isKingInCheck(moveKing(board, row, col + 2, isWhiteTurn), "black")
  ) {
    castlingMoves.push([row, col + 2]);
  }

  // Queenside castling for Black
  if (
    !isWhiteTurn &&
    castlingRights.blackQueenSide &&
    board[row][col - 1] === "" && // Empty square next to king
    board[row][col - 2] === "" && // Empty square for king to move to
    board[row][col - 3] === "" && // Empty square next to rook
    !isKingInCheck(board, "black") && // King is not currently in check
    !isKingInCheck(moveKing(board, row, col - 1, isWhiteTurn), "black") && // Path is not in check
    !isKingInCheck(moveKing(board, row, col - 2, isWhiteTurn), "black")
  ) {
    castlingMoves.push([row, col - 2]);
  }

  return castlingMoves;
};

// Function to check if a piece is movable
export const isPieceMovable = (piece, isWhiteTurn) => {
  return (
    (isWhiteTurn && piece === piece.toUpperCase()) || // If it's white's turn and piece is uppercase (white)
    (!isWhiteTurn && piece === piece.toLowerCase()) // If it's black's turn and piece is lowercase (black)
  );
};

// Function to simulate moving the king
export const moveKing = (board, row, col, isWhiteTurn) => {
  const newBoard = board.map((r) => [...r]);
  newBoard[row][col] = isWhiteTurn ? "K" : "k"; // Move king to a new square
  return newBoard;
};

// Find the position of the king on the board
const findKing = (board, color) => {
  const king = color === "white" ? "K" : "k";
  for (let row = 0; row < 8; row++) {
    for (let col = 0; col < 8; col++) {
      if (board[row][col] === king) return [row, col];
    }
  }
  return null;
};

// Generate legal moves for each piece
export const generateLegalMoves = (row, col, piece, board) => {
  let moves = [];
  switch (piece.toLowerCase()) {
    case "p":
      moves = generatePawnMoves(row, col, piece, board);
      break;
    case "r":
      moves = generateRookMoves(row, col, piece, board);
      break;
    case "n":
      moves = generateKnightMoves(row, col, piece, board);
      break;
    case "b":
      moves = generateBishopMoves(row, col, piece, board);
      break;
    case "q":
      moves = generateQueenMoves(row, col, piece, board);
      break;
    case "k":
      moves = generateKingMoves(row, col, piece, board);
      break;
    default:
      break;
  }
  return moves;
};

// Filter out moves that would put the player's own king in check
export const filterOutMovesThatCauseCheck = (
  fromRow,
  fromCol,
  moves,
  board,
  piece
) => {
  return moves.filter(([toRow, toCol]) => {
    const newBoard = board.map((r) => [...r]);
    newBoard[toRow][toCol] = piece;
    newBoard[fromRow][fromCol] = "";

    return !isKingInCheck(
      newBoard,
      piece === piece.toUpperCase() ? "white" : "black"
    );
  });
};

// Define move generation functions
export const generatePawnMoves = (row, col, piece, board) => {
  const moves = [];
  const direction = piece === "P" ? -1 : 1; // White moves up (-1), black moves down (+1)
  const startRow = piece === "P" ? 6 : 1; // Starting rows for pawns

  // Check if the pawn move is within board bounds before adding to moves
  if (
    row + direction >= 0 &&
    row + direction < 8 &&
    board[row + direction][col] === ""
  ) {
    moves.push([row + direction, col]);

    // Move forward two squares from the starting position
    if (
      row === startRow &&
      row + 2 * direction >= 0 &&
      row + 2 * direction < 8 &&
      board[row + 2 * direction][col] === ""
    ) {
      moves.push([row + 2 * direction, col]);
    }
  }

  // Capture diagonally to the left
  if (
    col > 0 && // Ensure left diagonal is within bounds
    row + direction >= 0 &&
    row + direction < 8 &&
    isOpponentPiece(row, col, row + direction, col - 1, board)
  ) {
    moves.push([row + direction, col - 1]);
  }

  // Capture diagonally to the right
  if (
    col < 7 && // Ensure right diagonal is within bounds
    row + direction >= 0 &&
    row + direction < 8 &&
    isOpponentPiece(row, col, row + direction, col + 1, board)
  ) {
    moves.push([row + direction, col + 1]);
  }

  return moves;
};

export const generateRookMoves = (row, col, piece, board) => {
  return generateLinearMoves(row, col, board, [
    [1, 0],
    [-1, 0],
    [0, 1],
    [0, -1],
  ]);
};

export const generateBishopMoves = (row, col, piece, board) => {
  return generateLinearMoves(row, col, board, [
    [1, 1],
    [-1, -1],
    [1, -1],
    [-1, 1],
  ]);
};

export const generateQueenMoves = (row, col, piece, board) => {
  return generateLinearMoves(row, col, board, [
    [1, 0],
    [-1, 0],
    [0, 1],
    [0, -1],
    [1, 1],
    [-1, -1],
    [1, -1],
    [-1, 1],
  ]);
};

export const generateKingMoves = (row, col, piece, board) => {
  return generateSingleStepMoves(row, col, board, [
    [1, 0],
    [-1, 0],
    [0, 1],
    [0, -1],
    [1, 1],
    [-1, -1],
    [1, -1],
    [-1, 1],
  ]);
};

export const generateKnightMoves = (row, col, piece, board) => {
  const moves = [
    [row + 2, col + 1],
    [row + 2, col - 1],
    [row - 2, col + 1],
    [row - 2, col - 1],
    [row + 1, col + 2],
    [row + 1, col - 2],
    [row - 1, col + 2],
    [row - 1, col - 2],
  ];

  return moves.filter(
    ([r, c]) =>
      r >= 0 &&
      r < 8 &&
      c >= 0 &&
      c < 8 &&
      (board[r][c] === "" || isOpponentPiece(row, col, r, c, board))
  );
};

// Helper functions for move generation
const generateLinearMoves = (row, col, board, directions) => {
  const moves = [];
  directions.forEach(([rowOffset, colOffset]) => {
    let r = row + rowOffset;
    let c = col + colOffset;
    while (r >= 0 && r < 8 && c >= 0 && c < 8) {
      if (board[r][c] === "") {
        moves.push([r, c]);
      } else if (isOpponentPiece(row, col, r, c, board)) {
        moves.push([r, c]);
        break;
      } else {
        break;
      }
      r += rowOffset;
      c += colOffset;
    }
  });
  return moves;
};

const generateSingleStepMoves = (row, col, board, directions) => {
  const moves = [];
  directions.forEach(([rowOffset, colOffset]) => {
    const r = row + rowOffset;
    const c = col + colOffset;
    if (
      r >= 0 &&
      r < 8 &&
      c >= 0 &&
      c < 8 &&
      (board[r][c] === "" || isOpponentPiece(row, col, r, c, board))
    ) {
      moves.push([r, c]);
    }
  });
  return moves;
};

const isOpponentPiece = (row, col, r, c, board) => {
  const piece = board[row][col];
  const target = board[r][c];
  return (
    piece &&
    target &&
    ((piece === piece.toUpperCase() && target === target.toLowerCase()) ||
      (piece === piece.toLowerCase() && target === target.toUpperCase()))
  );
};

// Chess AI Functions

export const evaluateBoard = (board) => {
  const pieceValues = {
    p: 10,
    n: 30,
    b: 30,
    r: 50,
    q: 90,
    k: 900,
  };

  let totalEvaluation = 0;

  for (let row = 0; row < 8; row++) {
    for (let col = 0; col < 8; col++) {
      const piece = board[row][col];
      if (piece) {
        const isWhite = piece === piece.toUpperCase();
        const value = pieceValues[piece.toLowerCase()];
        totalEvaluation += isWhite ? value : -value;
      }
    }
  }

  return totalEvaluation;
};

export const minimax = (board, depth, isMaximizingPlayer, alpha, beta) => {
  if (depth === 0 || isGameOver(board)) {
    return evaluateBoard(board);
  }

  const possibleMoves = getAllPossibleMoves(
    isMaximizingPlayer ? "white" : "black",
    board
  );

  if (isMaximizingPlayer) {
    let maxEvaluation = -Infinity;
    for (const move of possibleMoves) {
      const newBoard = makeMove(board, move);
      const moveEvaluation = minimax(newBoard, depth - 1, false, alpha, beta);
      maxEvaluation = Math.max(maxEvaluation, moveEvaluation);
      alpha = Math.max(alpha, moveEvaluation);
      if (beta <= alpha) break; // Alpha-Beta Pruning
    }
    return maxEvaluation;
  } else {
    let minEvaluation = Infinity;
    for (const move of possibleMoves) {
      const newBoard = makeMove(board, move);
      const moveEvaluation = minimax(newBoard, depth - 1, true, alpha, beta);
      minEvaluation = Math.min(minEvaluation, moveEvaluation);
      beta = Math.min(beta, moveEvaluation);
      if (beta <= alpha) break; // Alpha-Beta Pruning
    }
    return minEvaluation;
  }
};

export const makeMove = (board, move) => {
  const newBoard = board.map((r) => [...r]);
  const [startRow, startCol] = move.start;
  const [endRow, endCol] = move.end;
  newBoard[endRow][endCol] = newBoard[startRow][startCol];
  newBoard[startRow][startCol] = "";
  return newBoard;
};

const isGameOver = (board) => {
  // Implement logic to check for checkmate, stalemate, or draw.
  return false; // Placeholder implementation
};
