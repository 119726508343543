import React from "react";
import "./css/ChessPiece.css";

const pieceImages = {
  R: "♜",
  N: "♞",
  B: "♝",
  Q: "♛",
  K: "♚",
  P: "♙",
  r: "♜",
  n: "♞",
  b: "♝",
  q: "♛",
  k: "♚",
  p: "♟",
};

const ChessPiece = ({ piece }) => {
  if (!piece) return null;

  const isWhite = piece === piece.toUpperCase();

  return (
    <div className={`chess-piece ${isWhite ? "white-piece" : "black-piece"}`}>
      {pieceImages[piece]}
    </div>
  );
};

export default ChessPiece;
