import React, { useEffect } from "react";
import "./css/Releases.css";

function Releases() {
  // Array of YouTube video URLs
  const videoUrls = [
    "https://youtu.be/crZOZLxKwpY",
    "https://www.youtube.com/watch?v=PWiCRVXnGds&pp=ygUPbXkgZGVhIHJlYWNo",
    "https://www.youtube.com/watch?v=noV40_yaFD0&pp=ygUPIHJlYWNoIHZpZGVv",
    "https://www.youtube.com/watch?v=WNwo-NcIcHc&pp=ygUPbXkgZGVhciB4IGxlbml4",
    "https://www.youtube.com/watch?v=87-GJOk0oTI",
    "https://www.youtube.com/watch?v=t9fvCod0hq4",
    "https://www.youtube.com/watch?v=9axX_-qEWEg&pp=ygUPIHJlYWNoIHZpZGVv",
    "https://www.youtube.com/watch?v=sotfrNYMVsw",
  ];

  // Function to extract video ID from YouTube URLs
  function getVideoId(url) {
    // Regular expression to match YouTube video ID
    const regExp =
      /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  // Suppress the console errors for YouTube postMessage errors
  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (
        typeof args[0] === "string" &&
        args[0].includes("Failed to execute 'postMessage'")
      ) {
        return;
      }
      originalConsoleError(...args);
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, []);

  return (
    <div className="releases-container">
      <h2>Releases</h2>
      {/* Mapping through video URLs and rendering each video as an image preview */}
      {videoUrls.map((url, index) => {
        const videoId = getVideoId(url);
        console.log(videoId);
        const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

        return (
          <div className="video-preview" key={index}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <div className="video-thumbnail-wrapper">
                <img
                  src={thumbnailUrl}
                  alt="Video Thumbnail"
                  className="video-thumbnail"
                />
                <div className="play-button-rel">&#9654;</div>
              </div>
            </a>
            <div className="video-info"></div>
          </div>
        );
      })}
    </div>
  );
}

export default Releases;
