import React, { useState, useEffect, useRef, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  FaTimes,
  FaPlay,
  FaPlus,
  FaMinus,
  FaPause,
  FaClock,
  FaStop,
  FaSave,
  FaTrash,
} from "react-icons/fa";
import { useUser } from "./UserContext";
import "./css/PianoRollModal.css";
import InstrumentAudioContext from "./InstrumentAudioContext";
import { ClipLoader } from "react-spinners";
import { debounce } from "lodash";
import { usePatternInsSeq } from "./PatternInsSeqContext";

const generateNotes = () => {
  const notes = [];
  const noteNames = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  for (let octave = 0; octave < 5; octave++) {
    for (const note of noteNames) {
      notes.push(`${note}${octave}`);
    }
  }
  return notes.reverse(); // Reverse to start from C0 at the bottom
};

const PianoRollModal = ({
  instrument,
  initialPatternNo,
  onClose,
  setPianoRollIsPlaying,
  bpm,
  setBpm,
  selectedInstruments,
  setSelectedInstruments,
}) => {
  const notes = generateNotes();
  const [currentBar, setCurrentBar] = useState(0);
  const intervalRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [volume, setVolume] = useState(
    InstrumentAudioContext.getInstVolume(instrument)
  );
  const { user } = useUser();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMetronomeOn, setIsMetronomeOn] = useState(false);
  const [currentMetronomeBeat, setCurrentMetronomeBeat] = useState(0);
  const [selectedPianoRollPattern, setSelectedPianoRollPattern] = useState("");
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isVolumeChanging, setIsVolumeChanging] = useState(false);
  const [pianoRollPatterns, setPianoRollPatterns] = useState([]);
  const {
    fetchPianoRollPatterns,
    fetchNotesPianoRoll,
    savePianoRollPattern,
    deletePianoRollPattern,
  } = usePatternInsSeq();
  const [grid, setGrid] = useState(
    Array(16)
      .fill()
      .map(() => Array(notes.length).fill(false))
  );

  useEffect(() => {
    const fetchPatterns = async () => {
      const patterns = await fetchPianoRollPatterns();
      setPianoRollPatterns(patterns);
    };

    fetchPatterns();
  }, [fetchPianoRollPatterns]);
  useEffect(() => {
    if (initialPatternNo) {
      setSelectedPianoRollPattern(initialPatternNo);
      handlePianoRollPatternChange({ target: { value: initialPatternNo } });
    }
  }, [initialPatternNo]);
  useEffect(() => {
    InstrumentAudioContext.setInstVolume(volume, instrument);
  }, [volume, instrument]);

  useEffect(() => {
    if (setPianoRollIsPlaying) {
      setPianoRollIsPlaying(isPlaying);
    }
  }, [isPlaying, setPianoRollIsPlaying]);

  useEffect(() => {
    InstrumentAudioContext.loadAudio(instrument);
    return () => {
      InstrumentAudioContext.stopAllNotes();
    };
  }, [instrument]);

  useEffect(() => {
    InstrumentAudioContext.setBpm(bpm);
  }, [bpm]);

  useEffect(() => {
    if (isPlaying) {
      const interval = (60 / bpm) * 250;
      intervalRef.current = setInterval(() => {
        setCurrentBar((prevBar) => (prevBar + 1) % 16);
        if (isMetronomeOn) {
          setCurrentMetronomeBeat((prevBeat) => (prevBeat % 4) + 1);
          InstrumentAudioContext.playMetronomeSound(currentMetronomeBeat);
        }
      }, interval);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isPlaying, bpm, isMetronomeOn, currentMetronomeBeat]);

  useEffect(() => {
    if (isPlaying && !isVolumeChanging) {
      if (grid[currentBar]) {
        grid[currentBar].forEach((active, noteIndex) => {
          if (active) {
            const adjustedNoteIndex = notes.length - 1 - noteIndex;
            InstrumentAudioContext.playPianoRollNote(
              adjustedNoteIndex,
              notes,
              instrument
            );
          }
        });
      }
    }
  }, [currentBar, isPlaying, grid, notes, instrument, isVolumeChanging]);

  const handleMouseDown = (rowIndex, colIndex) => {
    setIsMouseDown(true);
    toggleCell(rowIndex, colIndex);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    InstrumentAudioContext.stopAllNotes();
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const element = document.elementFromPoint(touch.clientX, touch.clientY);
    if (element && element.dataset.row && element.dataset.col) {
      const rowIndex = parseInt(element.dataset.row, 10);
      const colIndex = parseInt(element.dataset.col, 10);
      toggleCell(rowIndex, colIndex);
    }
  };

  const handleTouchEnd = () => {
    setIsMouseDown(false);
    InstrumentAudioContext.stopAllNotes();
  };

  const toggleCell = (rowIndex, colIndex) => {
    const newGrid = grid.map((row, rIdx) =>
      row.map((cell, cIdx) =>
        rIdx === colIndex && cIdx === rowIndex ? !cell : cell
      )
    );
    setGrid(newGrid);

    const noteIndex = notes.length - 1 - rowIndex;
    InstrumentAudioContext.playNoteOnMouseDown(noteIndex, notes, instrument);
  };

  const increaseBpm = () => setBpm((prev) => Math.min(prev + 5, 300));
  const decreaseBpm = () => setBpm((prev) => Math.max(prev - 5, 20));
  const clearGrid = () => {
    setGrid(
      Array(16)
        .fill()
        .map(() => Array(notes.length).fill(false))
    );
  };

  const debouncedSetVolume = useCallback(
    debounce((volume) => {
      InstrumentAudioContext.setInstVolume(volume, instrument);
      setIsVolumeChanging(false);
    }, 100),
    [instrument]
  );

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    setIsVolumeChanging(true);
    debouncedSetVolume(newVolume);
  };

  const toggleMetronome = () => {
    setIsMetronomeOn((prev) => !prev);
    setCurrentMetronomeBeat(0);
  };

  const isNaturalNote = (note) => {
    return !note.includes("#");
  };

  const handleClose = () => {
    onClose(grid, selectedPianoRollPattern);
  };

  const handleStopClick = () => {
    setIsPlaying(false);
    setCurrentBar(0);
  };

  const handlePianoRollPatternChange = async (e) => {
    const patternNo = e.target.value;
    setSelectedPianoRollPattern(patternNo);

    const notesArray = await fetchNotesPianoRoll(patternNo);
    console.log(`Notes for pattern ${patternNo}:`, notesArray.join(", "));

    const newGrid = Array(16)
      .fill()
      .map(() => Array(notes.length).fill(false));

    notesArray.forEach((note) => {
      const [noteName, bar] = note.split(",");
      const rowIndex = notes.findIndex((n) => n === noteName);
      const colIndex = parseInt(bar, 10) - 1;
      if (rowIndex !== -1 && colIndex >= 0 && colIndex < 16) {
        newGrid[colIndex][rowIndex] = true;
      }
    });

    setGrid(newGrid);

    const updatedInstruments = selectedInstruments.map((inst) =>
      inst.InsName === instrument
        ? { ...inst, PianoRollPatternNo: patternNo }
        : inst
    );
    setSelectedInstruments(updatedInstruments);
  };

  const handleSavePattern = async () => {
    setIsLoadingSave(true);
    try {
      await savePianoRollPattern(
        selectedPianoRollPattern,
        grid,
        notes,
        setSelectedPianoRollPattern
      );
      const patterns = await fetchPianoRollPatterns();
      setPianoRollPatterns(patterns);
    } catch (error) {
      console.error("Failed to save piano roll pattern:", error);
    } finally {
      setIsLoadingSave(false);
    }
  };

  const handleDeletePattern = async () => {
    const parsedPatternNo = parseInt(selectedPianoRollPattern, 10);

    if (!parsedPatternNo || parsedPatternNo === 0) {
      alert("Invalid pattern number.");
      return;
    }

    if (window.confirm("Are you sure you want to delete this pattern?")) {
      setIsLoadingDelete(true);
      try {
        await deletePianoRollPattern(parsedPatternNo);
        const patterns = await fetchPianoRollPatterns();
        setPianoRollPatterns(patterns);
        setSelectedPianoRollPattern(""); // Reset the dropdown value to default

        // Clear the grid
        setGrid(
          Array(16)
            .fill()
            .map(() => Array(notes.length).fill(false))
        );
      } catch (error) {
        console.error("Failed to delete pattern:", error);
      } finally {
        setIsLoadingDelete(false);
      }
    }
  };
  const increaseOctave = () => {
    setGrid((prevGrid) => shiftOctave(prevGrid, 1));
  };

  const decreaseOctave = () => {
    setGrid((prevGrid) => shiftOctave(prevGrid, -1));
  };

  const shiftOctave = (grid, direction) => {
    const newGrid = Array.from({ length: 16 }, () =>
      Array(notes.length).fill(false)
    );
    for (let col = 0; col < grid.length; col++) {
      for (let row = 0; row < grid[col].length; row++) {
        if (grid[col][row]) {
          const note = notes[row];
          const noteName = note.slice(0, -1);
          let octave = parseInt(note.slice(-1), 10);
          octave += direction;
          const newNote = `${noteName}${octave}`;
          const newRowIndex = notes.findIndex((n) => n === newNote);
          if (newRowIndex !== -1) {
            newGrid[col][newRowIndex] = true;
          }
        }
      }
    }
    return newGrid;
  };
  return (
    <div
      className="piano-roll-modal"
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchEnd={handleTouchEnd}
    >
      <div className="piano-roll-modal-content">
        <div className="piano-roll-header">
          <h2 className="piano-roll-title">{instrument} Piano Roll</h2>
          <button onClick={handleClose} className="close-button">
            <FaTimes />
          </button>
        </div>
        <div className="pianoroll-controls">
          <button
            onClick={() => setIsPlaying(!isPlaying)}
            className="play-button-pianoroll"
          >
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
          <button onClick={handleStopClick} className="stop-button2">
            <FaStop />
          </button>
          <select
            className="roll-pattern-dropdown"
            value={selectedPianoRollPattern}
            onChange={handlePianoRollPatternChange}
          >
            <option value="">Select Piano Roll Pattern</option>
            {pianoRollPatterns.map((pattern) => (
              <option key={pattern.$id} value={pattern.patternNo}>
                Preset: {pattern.patternNo}
              </option>
            ))}
          </select>
          <button
            onClick={handleSavePattern}
            className="save-pattern-button"
            disabled={isLoadingSave}
          >
            {isLoadingSave ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              <FaSave />
            )}
          </button>
          <button
            onClick={handleDeletePattern}
            className="delete-pattern-button"
            disabled={isLoadingDelete}
          >
            {isLoadingDelete ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              <FaTrash />
            )}
          </button>
        </div>
        <div className="option-pattern">
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className="volume-slider"
          />
          <button onClick={toggleMetronome} className="save-pattern-button">
            <FaClock />
          </button>
          <button onClick={clearGrid} className="clear-button">
            Clear
          </button>
          <div className="bpm-controls">
            <button onClick={decreaseBpm} className="bpm-button">
              <FaMinus />
            </button>
            <span className="bpm-display">{bpm} BPM</span>
            <button onClick={increaseBpm} className="bpm-button">
              <FaPlus />
            </button>
          </div>
          <div className="octave-controls">
            <button onClick={decreaseOctave} className="octave-button">
              <FaMinus />
            </button>
            <p> Octave </p>
            <button onClick={increaseOctave} className="octave-button">
              <FaPlus />
            </button>
          </div>
        </div>
        <div
          className="piano-roll-table-container"
          onTouchMove={handleTouchMove}
        >
          <table className="piano-roll-table">
            <tbody>
              {notes.map((note, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={
                    isNaturalNote(note) ? "natural-note" : "sharp-note"
                  }
                >
                  <td className="sticky-left">{note}</td>
                  {[...Array(16)].map((_, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ width: "50px" }}
                      className={`${
                        grid[colIndex] && grid[colIndex][rowIndex]
                          ? "active"
                          : ""
                      } ${
                        colIndex === currentBar &&
                        grid[colIndex] &&
                        grid[colIndex][rowIndex]
                          ? "playing"
                          : ""
                      } ${
                        [0, 4, 8, 12].includes(colIndex)
                          ? "highlighted-cell"
                          : ""
                      }`}
                      onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                      onTouchStart={() => handleMouseDown(rowIndex, colIndex)}
                      data-row={rowIndex}
                      data-col={colIndex}
                    ></td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th className="sticky-left corner-box">Keys</th>
                {[...Array(16)].map((_, index) => (
                  <th
                    key={index}
                    style={{ width: "200px" }}
                    className={index === currentBar ? "current-bar" : ""}
                  >
                    {index + 1}
                  </th>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PianoRollModal;
