import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useUser } from "./UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import "./css/Checkout.css";

const statesOfIndia = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];

const Checkout = () => {
  const location = useLocation();
  const { cart } = location.state || {};
  const { user } = useUser();
  const [loadingPay, setLoadingPay] = useState(false);
  const FunctionUrl = "https://api.hxabyte.com/pay";
  const apiKey =
    "940c7e6bc8b9239fa11a0dde2ca9869c11bf1a69fba92b4b0e651108ddf5f2ee913ba903e9e3a7610e4e6c842cc80d66085c206bd819e4cae6127a1d040e1143d824eec99360bd2cef451a2fefe947a4b5b5979fe9de5fd8117b865c3cbc46fab37bb05a5392fac8769daaaaeb4ff24a3f16072625f84d15033d724c0599494a";

  const calculateTotalPrice = (cart) => {
    return cart.reduce(
      (total, item) =>
        total + Number(item.totalCost || item.cost * (item.quantity || 1)),
      0
    );
  };

  const totalPrice = calculateTotalPrice(cart);

  const handlePayment = async (values) => {
    setLoadingPay(true); // Start loading

    // Helper function to format each field by trimming and ensuring a comma is added only if necessary
    const formatField = (field) => field.trim().replace(/,\s*$/, "");

    // Concatenate address fields into a single string with a space and comma after each field
    const formattedAddress = [
      values.address.flat ? formatField(values.address.flat) : "",
      values.address.street ? formatField(values.address.street) : "",
      values.address.area ? formatField(values.address.area) : "",
      values.address.city ? formatField(values.address.city) : "",
      values.address.state ? formatField(values.address.state) : "",
      values.address.postalCode ? formatField(values.address.postalCode) : "",
    ]
      .filter(Boolean) // Remove empty fields
      .join(", ") // Join the non-empty fields with a comma and space
      .replace(/,\s*$/, "") // Remove trailing commas, if any
      .trim();

    const load = {
      cart,
      totalPrice,
      mobileNumber: values.mobileNumber,
      customerName: user.name,
      email: user.email,
      address: formattedAddress, // Save address as a single string
    };

    try {
      const response = await fetch(FunctionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Appwrite-Project": "65eef2e3e025ba72a15c",
          "X-Appwrite-Key": apiKey,
        },
        body: JSON.stringify(load), // Ensure the payload is stringified
      });

      const data = await response.json();
      if (
        data &&
        data.data &&
        data.data.instrumentResponse &&
        data.data.instrumentResponse.redirectInfo
      ) {
        window.location.href = data.data.instrumentResponse.redirectInfo.url;
      } else {
        console.error("Invalid response from server", data);
      }
    } catch (err) {
      console.error("Payment initiation error:", err);
    } finally {
      setLoadingPay(false); // Stop loading
    }
  };

  const groupedItems = groupItemsByProductType(cart);
  const hasFashionItems =
    groupedItems.fashion && groupedItems.fashion.length > 0;

  function groupItemsByProductType(cart) {
    return cart.reduce((groups, item) => {
      const type = item.productType;
      if (!groups[type]) {
        groups[type] = [];
      }
      groups[type].push(item);
      return groups;
    }, {});
  }

  return (
    <div className="checkout-container">
      <div className="form-container-checkout">
        <h2 className="title-checkout">Checkout</h2>
        <div>
          {cart && cart.length > 0 ? (
            <div>
              {/* <h3 className="cart-title-checkout">Cart Items:</h3> */}
              {Object.keys(groupedItems).map((type) => (
                <div key={type}>
                  <h4 className="cart-section-title-checkout">
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </h4>
                  <ul className="cart-item-list-checkout">
                    {groupedItems[type].map((item, index) => (
                      <li key={index} className="cart-item-checkout">
                        <span className="product-name-checkout">
                          {item.productName}
                          {item.productType === "fashion" &&
                            ` x ${item.quantity}`}
                        </span>
                        <span className="product-cost-checkout">
                          {item.productType === "fashion"
                            ? `₹${item.cost} x ${item.quantity} = ₹${
                                item.cost * item.quantity
                              }`
                            : `₹${item.cost}`}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <p className="total-price-checkout">Total Price: ₹{totalPrice}</p>
            </div>
          ) : (
            <p>No items in the cart.</p>
          )}
        </div>
        <div>
          <h3 className="payment-title-checkout">Payment Details</h3>
          <Formik
            initialValues={{
              mobileNumber: "",
              address: {
                flat: "",
                street: "",
                area: "",
                city: "",
                state: "",
                postalCode: "",
              },
            }}
            validate={(values) => {
              const errors = {};
              if (!/^[0-9]+$/.test(values.mobileNumber)) {
                errors.mobileNumber = "Only numbers are allowed";
              }
              if (hasFashionItems) {
                if (!values.address.flat) {
                  errors.address = { ...errors.address, flat: "Required" };
                }
                if (!values.address.street) {
                  errors.address = { ...errors.address, street: "Required" };
                }
                if (!values.address.area) {
                  errors.address = { ...errors.address, area: "Required" };
                }
                if (!values.address.city) {
                  errors.address = { ...errors.address, city: "Required" };
                }
                if (!values.address.state) {
                  errors.address = { ...errors.address, state: "Required" };
                }
                if (!values.address.postalCode) {
                  errors.address = {
                    ...errors.address,
                    postalCode: "Required",
                  };
                } else if (!/^[0-9]+$/.test(values.address.postalCode)) {
                  errors.address = {
                    ...errors.address,
                    postalCode: "Only numbers are allowed",
                  };
                }
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              handlePayment(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, values }) => (
              <Form>
                <label className="form-label-checkout">Mobile Number</label>
                <Field
                  className="input-field-checkout"
                  type="text"
                  name="mobileNumber"
                  required
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                <ErrorMessage
                  name="mobileNumber"
                  component="div"
                  className="error-message-checkout"
                />

                {hasFashionItems && (
                  <>
                    <h3 className="address-title-checkout">Address Details</h3>
                    <label className="form-label-checkout">Flat/Door No.</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.flat"
                      required
                    />
                    <ErrorMessage
                      name="address.flat"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">Street</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.street"
                      required
                    />
                    <ErrorMessage
                      name="address.street"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">Area</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.area"
                      required
                    />
                    <ErrorMessage
                      name="address.area"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">City</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.city"
                      required
                    />
                    <ErrorMessage
                      name="address.city"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">State</label>
                    <Field
                      as="select"
                      className="select-field-checkout"
                      name="address.state"
                      required
                    >
                      <option value="" label="Select state" />
                      {statesOfIndia.map((state) => (
                        <option key={state} value={state} label={state} />
                      ))}
                    </Field>
                    <ErrorMessage
                      name="address.state"
                      component="div"
                      className="error-message-checkout"
                    />

                    <label className="form-label-checkout">Postal Code</label>
                    <Field
                      className="input-field-checkout"
                      type="text"
                      name="address.postalCode"
                      required
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />
                    <ErrorMessage
                      name="address.postalCode"
                      component="div"
                      className="error-message-checkout"
                    />
                  </>
                )}

                <button
                  className="submit-button-checkout"
                  type="submit"
                  disabled={loadingPay || isSubmitting}
                >
                  {loadingPay ? (
                    <ClipLoader
                      color="#ffffff"
                      loading={loadingPay}
                      size={20}
                    />
                  ) : (
                    "Proceed to Pay NOW"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
