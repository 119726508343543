import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import { useUser } from "./UserContext";
import { Databases, Storage } from "appwrite";
import client from "./appwriteConfig";
import { v4 as uuidv4 } from "uuid";
import "./css/Home.css";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { BeatLoader } from "react-spinners";
import { FaShoppingCart } from "react-icons/fa";
import { ClipLoader } from "react-spinners";

import Slider from "react-slick";
import Footer from "./Footer";

function Home() {
  const {
    user,
    cart,
    addToCart,
    fetchCartData,
    storage,
    currentTrack,
    setCurrentTrack,
    isPlaying,
    togglePlay,
    cartLoading,
  } = useUser();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (user) {
      fetchCartData();
    }
  }, [user]);

  useEffect(() => {
    const fetchProducts = async () => {
      const database = new Databases(client, "default");
      try {
        const { documents } = await database.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_BEATS_ID
        );
        setProducts(documents);
        setLoading(false);

        // console.log("User after phone login:", user);
      } catch (error) {
        console.error("Failed to fetch products", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const isInCart = (productId) =>
    cart.some((item) => item.productId === productId);

  const handlePlayPause = (product) => {
    if (currentTrack && currentTrack.$id === product.$id) {
      togglePlay();
    } else {
      setCurrentTrack(product);
      if (!isPlaying) {
        togglePlay();
      }
    }
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const sortProducts = (a, b) => {
    if (sortBy === "cost-descending") {
      return b.cost - a.cost;
    } else if (sortBy === "cost-ascending") {
      return a.cost - b.cost;
    } else if (sortBy === "tempo-descending") {
      return b.tempo - a.tempo;
    } else if (sortBy === "tempo-ascending") {
      return a.tempo - b.tempo;
    } else {
      return 0;
    }
  };

  const sortedProducts = [...products].sort(sortProducts);

  const handleAddToCart = (product) => {
    if (!isInCart(product.$id)) {
      const productWithType = {
        ...product,
        productType: "beat", // Add productType here
      };
      addToCart(productWithType); // Pass the product with productType set
    }
  };

  return (
    <div className="document-list-container">
      <Slider {...settings}>
        <div className="banner">
          <h3>
            <a href="/">
              <img src="./assets/banner/newbeats.jpg" alt="Slide 1" />
            </a>
          </h3>
        </div>
        <div className="banner">
          <h3>
            <a href="/learnmusic">
              <img src="./assets/banner/leanmusic.jpg" alt="Slide 2" />
            </a>
          </h3>
        </div>
        <div className="banner">
          <h3>
            <a href="/beatmaker">
              <img src="./assets/banner/beatmaker.jpg" alt="Slide 3" />
            </a>
          </h3>
        </div>
        <div className="banner">
          <h3>
            <a href="/fashion">
              <img src="./assets/banner/fashion.jpg" alt="Slide 3" />
            </a>
          </h3>
        </div>
        <div className="banner">
          <h3>
            <a href="/releases">
              <img src="./assets/banner/releases.jpg" alt="Slide 4" />
            </a>
          </h3>
        </div>
      </Slider>
      <div className="home-container">
        <h2> 🎵 Discover Fresh Beats Daily! </h2>
        <select
          className="sorting-dropdown"
          value={sortBy}
          onChange={handleSortChange}
        >
          <option value="sort-option">Sort by...</option>
          <option value="cost-descending"> Cost: Des</option>
          <option value="cost-ascending"> Cost: Asc</option>
          <option value="tempo-descending">Tempo: Des</option>
          <option value="tempo-ascending">Tempo: Asc</option>
        </select>
      </div>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <BeatLoader color="#3498db" />
        </div>
      ) : products.length > 0 ? (
        <div className="document-grid">
          {sortedProducts.map((product) => (
            <div key={uuidv4()} className="product-row">
              <div className="product-image-beats">
                <img
                  src={storage.getFileView(
                    process.env.REACT_APP_BUCKET_BEATS_ID,
                    product.imageUrl
                  )}
                  alt="Product"
                />
                <button
                  onClick={() => handlePlayPause(product)}
                  className="play-button"
                >
                  {currentTrack &&
                  currentTrack.$id === product.$id &&
                  isPlaying ? (
                    <BsPauseFill />
                  ) : (
                    <BsPlayFill />
                  )}
                </button>
              </div>
              <div className="artist-beat-info">
                <div className="product-info beat-name">{product.beatName}</div>
                <div className="product-info artist-name">
                  {product.artistName}
                </div>
                <div className="product-info">{product.genre}</div>
                <div className="product-info">{product.tempo} bpm</div>
              </div>
              <div className="cost-cart-info">
                <div className="product-info cost">{`₹${product.cost}`}</div>
                <button
                  className="addCart-button"
                  onClick={() => handleAddToCart(product)}
                  disabled={isInCart(product.$id)}
                >
                  {cartLoading[product.$id] ? (
                    <>
                      <ClipLoader size={7} color="#FFF" />
                      {"   "}
                    </>
                  ) : isInCart(product.$id) ? (
                    <> In </>
                  ) : (
                    <> Add </>
                  )}
                  <FaShoppingCart />
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No products found.</p>
      )}
    </div>
  );
}

export default Home;
