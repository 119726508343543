import React from "react";

function Refund() {
  return (
    <div>
      <h2>Refund Policy</h2>
      <p>
        We offer a refund policy for all digital purchases made through our
        website. If you are not satisfied with your purchase, please read the
        following guidelines:
      </p>
      <ul>
        <li>
          <strong>Courses:</strong> Refunds are available within 7 days of
          purchase. If you're not satisfied with a course, you can request a
          refund within 7 days for a full reimbursement.
        </li>
        <li>
          <strong>Beats and other digital products:</strong> Due to the nature
          of digital downloads, refunds are not applicable for beats or other
          digital products.
        </li>
      </ul>
      <p>
        To initiate a refund request for a course, please contact our support
        team within 7 days of purchase with your order details. Refunds will be
        processed within 5-7 business days upon approval.
      </p>
    </div>
  );
}

export default Refund;
