import React from "react";
import ChessPiece from "./ChessPiece";
import "./css/Square.css";

const Square = ({ row, col, piece, isHighlighted, inCheck, onClick }) => {
  const isLight = (row + col) % 2 === 0;
  return (
    <div
      className={`square ${isLight ? "light" : "dark"} ${
        isHighlighted ? "highlighted" : ""
      } ${inCheck ? "in-check" : ""}`} // Highlight king's square in check
      onClick={onClick}
    >
      {piece && <ChessPiece piece={piece} />}
    </div>
  );
};

export default Square;
