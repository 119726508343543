import React, { useRef, useState } from "react";

const Athumb = () => {
  const [thumbnail, setThumbnail] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    const videoURL = URL.createObjectURL(file);
    videoRef.current.src = videoURL;
    videoRef.current.load();

    videoRef.current.onloadedmetadata = () => {
      setVideoLoaded(true);
    };
  };

  const captureThumbnail = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    // Set currentTime to 0 to capture the first frame
    video.currentTime = 1;

    video.onseeked = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      setThumbnail(canvas.toDataURL("image/png"));
    };
  };

  return (
    <div>
      <h2>Upload Video to Generate Thumbnail</h2>
      <input type="file" accept="video/*" onChange={handleVideoUpload} />
      <video ref={videoRef} style={{ display: "none" }}></video>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      {videoLoaded && (
        <button onClick={captureThumbnail}>Generate Thumbnail</button>
      )}
      {thumbnail && (
        <div>
          <h3>Thumbnail:</h3>
          <img src={thumbnail} alt="Video Thumbnail" />
        </div>
      )}
    </div>
  );
};

export default Athumb;
